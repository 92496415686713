/* eslint-disable */
// source: controlStatusEvents.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.DeleteEvent', null, global);
goog.exportSymbol('proto.InsertEvent', null, global);
goog.exportSymbol('proto.ObjectType', null, global);
goog.exportSymbol('proto.PingEvent', null, global);
goog.exportSymbol('proto.ProjectStatusEvent', null, global);
goog.exportSymbol('proto.ProjectStatusEvent.EventCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProjectStatusEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ProjectStatusEvent.oneofGroups_);
};
goog.inherits(proto.ProjectStatusEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProjectStatusEvent.displayName = 'proto.ProjectStatusEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InsertEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InsertEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InsertEvent.displayName = 'proto.InsertEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteEvent.displayName = 'proto.DeleteEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PingEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PingEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PingEvent.displayName = 'proto.PingEvent';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ProjectStatusEvent.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.ProjectStatusEvent.EventCase = {
  EVENT_NOT_SET: 0,
  INSERT: 1,
  DELETE: 2,
  PING: 3
};

/**
 * @return {proto.ProjectStatusEvent.EventCase}
 */
proto.ProjectStatusEvent.prototype.getEventCase = function() {
  return /** @type {proto.ProjectStatusEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.ProjectStatusEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProjectStatusEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.ProjectStatusEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProjectStatusEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectStatusEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    insert: (f = msg.getInsert()) && proto.InsertEvent.toObject(includeInstance, f),
    pb_delete: (f = msg.getDelete()) && proto.DeleteEvent.toObject(includeInstance, f),
    ping: (f = msg.getPing()) && proto.PingEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProjectStatusEvent}
 */
proto.ProjectStatusEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProjectStatusEvent;
  return proto.ProjectStatusEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProjectStatusEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProjectStatusEvent}
 */
proto.ProjectStatusEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.InsertEvent;
      reader.readMessage(value,proto.InsertEvent.deserializeBinaryFromReader);
      msg.setInsert(value);
      break;
    case 2:
      var value = new proto.DeleteEvent;
      reader.readMessage(value,proto.DeleteEvent.deserializeBinaryFromReader);
      msg.setDelete(value);
      break;
    case 3:
      var value = new proto.PingEvent;
      reader.readMessage(value,proto.PingEvent.deserializeBinaryFromReader);
      msg.setPing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProjectStatusEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProjectStatusEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProjectStatusEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectStatusEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsert();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.InsertEvent.serializeBinaryToWriter
    );
  }
  f = message.getDelete();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeleteEvent.serializeBinaryToWriter
    );
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.PingEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional InsertEvent insert = 1;
 * @return {?proto.InsertEvent}
 */
proto.ProjectStatusEvent.prototype.getInsert = function() {
  return /** @type{?proto.InsertEvent} */ (
    jspb.Message.getWrapperField(this, proto.InsertEvent, 1));
};


/** @param {?proto.InsertEvent|undefined} value */
proto.ProjectStatusEvent.prototype.setInsert = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.ProjectStatusEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ProjectStatusEvent.prototype.clearInsert = function() {
  this.setInsert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectStatusEvent.prototype.hasInsert = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteEvent delete = 2;
 * @return {?proto.DeleteEvent}
 */
proto.ProjectStatusEvent.prototype.getDelete = function() {
  return /** @type{?proto.DeleteEvent} */ (
    jspb.Message.getWrapperField(this, proto.DeleteEvent, 2));
};


/** @param {?proto.DeleteEvent|undefined} value */
proto.ProjectStatusEvent.prototype.setDelete = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.ProjectStatusEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ProjectStatusEvent.prototype.clearDelete = function() {
  this.setDelete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectStatusEvent.prototype.hasDelete = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PingEvent ping = 3;
 * @return {?proto.PingEvent}
 */
proto.ProjectStatusEvent.prototype.getPing = function() {
  return /** @type{?proto.PingEvent} */ (
    jspb.Message.getWrapperField(this, proto.PingEvent, 3));
};


/** @param {?proto.PingEvent|undefined} value */
proto.ProjectStatusEvent.prototype.setPing = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.ProjectStatusEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 */
proto.ProjectStatusEvent.prototype.clearPing = function() {
  this.setPing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectStatusEvent.prototype.hasPing = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InsertEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.InsertEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InsertEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InsertEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objecttype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    object: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InsertEvent}
 */
proto.InsertEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InsertEvent;
  return proto.InsertEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InsertEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InsertEvent}
 */
proto.InsertEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.ObjectType} */ (reader.readEnum());
      msg.setObjecttype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InsertEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InsertEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InsertEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InsertEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjecttype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getObject();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.InsertEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.InsertEvent.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ObjectType objectType = 2;
 * @return {!proto.ObjectType}
 */
proto.InsertEvent.prototype.getObjecttype = function() {
  return /** @type {!proto.ObjectType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.ObjectType} value */
proto.InsertEvent.prototype.setObjecttype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string object = 3;
 * @return {string}
 */
proto.InsertEvent.prototype.getObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.InsertEvent.prototype.setObject = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objecttype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteEvent}
 */
proto.DeleteEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteEvent;
  return proto.DeleteEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteEvent}
 */
proto.DeleteEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.ObjectType} */ (reader.readEnum());
      msg.setObjecttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjecttype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.DeleteEvent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.DeleteEvent.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ObjectType objectType = 2;
 * @return {!proto.ObjectType}
 */
proto.DeleteEvent.prototype.getObjecttype = function() {
  return /** @type {!proto.ObjectType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.ObjectType} value */
proto.DeleteEvent.prototype.setObjecttype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PingEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.PingEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PingEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingEvent.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PingEvent}
 */
proto.PingEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PingEvent;
  return proto.PingEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PingEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PingEvent}
 */
proto.PingEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PingEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PingEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PingEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.ObjectType = {
  DIAGRAM: 0,
  TYPE: 1,
  RELATION: 2,
  PATTERN: 3,
  AVAILABLEATTRIBUTES: 4,
  TYPESEARCH: 5,
  PATTERNINSTANCE: 6,
  PLANTITEMINDEX: 7,
  CONFLICT: 8,
  TARGETCOLUMNS: 9,
  PROBLEMS: 10,
  TAGLIST: 11,
  TYPEEXAMPLES: 12,
  TYPESUGGESTION: 13,
  INSTRUMENTINDEXFILE: 14,
  INSTRUMENTINDEXCOLUMNS: 15,
  STATUS: 16,
  PATTERNSUGGESTION: 17,
  PROJECTPROPERTY: 18
};

goog.object.extend(exports, proto);
