import { LOADING_USER, USER_EXPIRED, USER_FOUND, USER_LOADED } from "redux-oidc";
import { IStoreActions } from ".";

export interface OidcState {
    loadingUser: boolean;
    userLoaded: boolean;
    userFound: boolean;
    userExpired: boolean;
}

const initialState = {
    loadingUser: false,
    userLoaded: false,
    userFound: false,
    userExpired: false,
};

export function oidcStateReducer(state = initialState, action: IStoreActions): OidcState {
    switch (action.type) {
        case LOADING_USER: {
            return {
                ...state,
                loadingUser: true
            };
        }
        case USER_LOADED: {
            return {
                ...state,
                userLoaded: true
            };
        }
        case USER_FOUND: {
            localStorage.removeItem('reauthenticated');
            return {
                ...state,
                userFound: true
            };
        }
        case USER_EXPIRED: {
            return {
                ...state,
                userExpired: true
            };
        }
        default: {
            return state;
        }
    }
}

