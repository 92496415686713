const env = process.env.NODE_ENV;

const DEFAULT_VARS_VALUES = [
    '$PORTAL_SERVER_URI',
    '$OIDC_AUTHORITY_URI',
    '$OIDC_CLIENT_ID',
    '$OIDC_CLIENT_SECRET'
];

const isEmpty = (value: string | undefined | null): boolean => {
    return value === null || value === undefined || value === '' || DEFAULT_VARS_VALUES.indexOf(value) > -1;
};

let ibasename;
let iAPI_URL;
let iGRPC_API_URL;
if (env === 'development') {
    ibasename = 'http://localhost';
    iAPI_URL = ibasename + ':8095/api';
    iGRPC_API_URL = ibasename + ':6571';
    //ibasename = 'http://portal.local/mb-for-control';
    //iAPI_URL = ibasename + '/api';
    //iGRPC_API_URL = ibasename + '/grpc';
} else {
    // @ts-ignore
    let PORTAL_SERVER_URI = window.__PORTAL_SERVER_URI__;
    if (isEmpty(PORTAL_SERVER_URI)) {
        PORTAL_SERVER_URI = process.env.REACT_APP_PORTAL_SERVER_URI;
    }

    ibasename = PORTAL_SERVER_URI;
    iAPI_URL = ibasename + '/api';
    iGRPC_API_URL = ibasename + '/grpc';
}
export const basename = ibasename;
export const API_URL = iAPI_URL;
export const GRPC_API_URL = iGRPC_API_URL;

export const REDIRECT_URI = '__REDIRECT_URI__';

export const USE_PORTAL = env !== 'development';

// @ts-ignore
let OIDC_AUTHORITY_URI = window.__OIDC_AUTHORITY_URI__;
if (isEmpty(OIDC_AUTHORITY_URI)) {
    OIDC_AUTHORITY_URI = process.env.REACT_APP_OIDC_AUTHORITY_URI;
}

export const authority = OIDC_AUTHORITY_URI;

// @ts-ignore
let OIDC_CLIENT_ID = window.__OIDC_CLIENT_ID__;
if (isEmpty(OIDC_CLIENT_ID)) {
    OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID ? process.env.REACT_APP_OIDC_CLIENT_ID : 'client';
}
export const client_id = OIDC_CLIENT_ID;

// @ts-ignore
let OIDC_CLIENT_SECRET = window.__OIDC_CLIENT_SECRET__;
if (isEmpty(OIDC_CLIENT_SECRET)) {
    OIDC_CLIENT_SECRET = process.env.REACT_APP_OIDC_CLIENT_SECRET ? process.env.REACT_APP_OIDC_CLIENT_SECRET : 'secret';
}
export const client_secret = OIDC_CLIENT_SECRET;
