import Konva from 'konva';
import { Transform } from 'konva/types/Util';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Group, Layer } from 'react-konva';
import { Extent } from './Extent';
import { childrenByTagName, firstChildByTagName, getColor, getComponentName, getExtent, getFillColor, getId, getLocation, getUnitVector3, readCoordinates, readNumericAttribute, readShapeGraphics } from './ProteusXML';
import { getAvailableFont } from './Fonts';

interface Props {
    model: Document;
    debugHitCanvas: boolean;
    notificationCallback: (message: string) => void;
    colorMode: number;
}

export function ProteusRenderer(props: Props) {
    const { model, debugHitCanvas, notificationCallback, colorMode } = props;
    const layerRef: React.RefObject<Konva.Layer> = useRef(null);
    const shapeGraphics = readShapeGraphics(model, colorMode, notificationCallback);

    useEffect(() => {
        const layer = layerRef.current!;
        layer.removeChildren();
        const consumer = (node: Konva.Shape) => {
            if (node.stroke) {
                if (colorMode === 1) {
                    node.stroke('rgb(0,0,0');
                }
                if (colorMode === 2) {
                    node.stroke('rgb(255,255,255');
                }
            }
            else {
                node.getChildren().each((node) => {
                    if (colorMode === 1) {
                        node.stroke('rgb(0,0,0');
                    }
                    if (colorMode === 2) {
                        node.stroke('rgb(255,255,255');
                    }
                });
            }
            layer.add(node);
        };
        renderProteusElement(readPresentation, DISABLE_LISTENING, model.getRootNode() as Element, consumer, shapeGraphics, notificationCallback, colorMode);
        layer.batchDraw();
    }, [model, notificationCallback]);

    useEffect(() => {
        const layer = layerRef.current!;
        const debugHitCanvasEnabled_ = (layer as any).debugHitCanvasEnabled;
        const debugHitCanvasEnabled = typeof debugHitCanvasEnabled_ === 'boolean' ? debugHitCanvasEnabled_ : false;
        if (debugHitCanvasEnabled !== debugHitCanvas) {
            (layer as any).debugHitCanvasEnabled = debugHitCanvas;
            layer.toggleHitCanvas();
            layer.batchDraw();
        }
    }, [debugHitCanvas]);

    return <Layer ref={layerRef} scaleX={1} scaleY={-1} offsetY={0} />;
}

const ctx = document.createElement('canvas').getContext('2d')!;

function measureText(text: string, font: string): TextMetrics {
    ctx.font = `100px ${font}`;
    return ctx.measureText(text);
}

type ReadPresentation = (element: Element) => Konva.ShapeConfig;

function readPresentation(element: Element): Konva.ShapeConfig {
    const presentations = element.getElementsByTagName('Presentation');
    if (presentations.length !== 1)
        return {};
    const presentation = presentations[0];

    const lineWeight = readNumericAttribute(presentation, 'LineWeight', 0.01);

    const r = readNumericAttribute(presentation, 'R', 0);
    const g = readNumericAttribute(presentation, 'G', 0);
    const b = readNumericAttribute(presentation, 'B', 0);

    return {
        stroke: 'rgb(' + Math.floor(r * 255) + ',' + Math.floor(g * 255) + ',' + Math.floor(b * 255) + ')',
        fill: '#00000000',
        strokeWidth: lineWeight * 2,
        hitStrokeWidth: lineWeight * 10
    };
}

const DISABLE_LISTENING: Konva.ShapeConfig = {
    listening: false,
    hitStrokeWidth: 0,
};

function renderPolyLine(element: Element, listeningConfig: Konva.ShapeConfig): Konva.Shape {
    const presentation = readPresentation(element);

    // Read coordinates into a flat array
    const coordinates = element.getElementsByTagName("Coordinate");
    const points = [];
    for (let i = 0; i < coordinates.length; ++i) {
        const coordinate = coordinates[i];
        points.push(readNumericAttribute(coordinate, 'X', 0));
        points.push(readNumericAttribute(coordinate, 'Y', 0));
    }

    // Is the polyline closed?
    let closed = false;
    if (points[0] === points[points.length - 2] && points[1] === points[points.length - 1]) {
        closed = true;
        points.splice(points.length - 2, 2);
    }

    // Create shape
    return new Konva.Line({
        ...presentation,
        points,
        closed,
        ...listeningConfig,
    });
}

const DEFAULT_TRANSFORM = new Konva.Transform([1.0, 0.0, 0.0, 1.0, 0.0, 0.0]);
const DEFAULT_REF = { x: 1.0, y: 0.0, z: 0.0 };
const DEFAULT_AXIS = { x: 0.0, y: 0.0, z: 1.0 };

function getPositionAsKonvaTransform(element: Element): Konva.Transform {
    const position = firstChildByTagName(element, 'Position');
    if (position === undefined)
        return DEFAULT_TRANSFORM.copy();

    const loc = getLocation(position);
    const ref = getUnitVector3(position, "Reference") || DEFAULT_REF;
    const axis = getUnitVector3(position, "Axis") || DEFAULT_AXIS;

    return new Konva.Transform([
        ref.x,
        ref.y,
        // Cross product of ref and axis
        ref.z * axis.y - ref.y * axis.z,
        ref.x * axis.z - ref.z * axis.x,
        loc.x,
        loc.y
    ]);
}

function getScaleAsKonvaTransform(element: Element): Konva.Transform {
    const scale = firstChildByTagName(element, 'Scale');
    if (scale === undefined)
        return DEFAULT_TRANSFORM.copy();

    const sx = readNumericAttribute(scale, 'X', 1.0);
    const sy = readNumericAttribute(scale, 'Y', 1.0);

    const tr = new Konva.Transform();
    tr.scale(sx, sy);
    return tr;
}

export function getKonvaTransform(element: Element): Konva.Transform {
    const tr = new Konva.Transform();
    tr.multiply(getPositionAsKonvaTransform(element));
    tr.multiply(getScaleAsKonvaTransform(element));
    return tr;
}

const DEFAULT_JUSTIFICATION = "LeftBottom";

function renderText(element: Element, listeningConfig: Konva.ShapeConfig, notificationCallback: (message: string) => void, colorMode: number): Konva.Shape[] {
    const extent = getExtent(element);

    /*
        float strokeWeight = element.getChild("Presentation").getAttribute("LineWeight").getFloatValue();
    */
    let color = getColor(element);
    color = color ? color : 'black';
    if (colorMode === 1) {
        color = 'black';
    }
    if (colorMode === 2) {
        color = 'white';
    }

    // Textual content
    let strings = childrenByTagName(element, 'String').map((el) => el.getAttribute('Value')).filter((el) => el).map((el) => el!);
    if (strings.length === 0) {
        const string = element.getAttribute('String');
        if (string)
            strings = [string];
        else
            return [];
    }

    // Font
    let fontValue = element.getAttribute("Font");
    // Surround with "" so that special characters like + don't cause issues
    fontValue = fontValue ? "\"" + fontValue + "\"" : null;
    const font = getAvailableFont(fontValue, notificationCallback);
    const desiredHeight = readNumericAttribute(element, 'Height', 10);
    const width = readNumericAttribute(element, 'Width', 0);

    // Position
    const tr = getPositionAsKonvaTransform(element);
    if (tr === undefined)
        return [];
    //const {x: orgX, y: orgY} = tr.getTranslation();

    // Angle
    let textAngle = readNumericAttribute(element, 'TextAngle', 0) * Math.PI / 180.0;
    while (textAngle < 0)
        textAngle += Math.PI * 2;
    while (textAngle > Math.PI * 2)
        textAngle -= Math.PI * 2;
    tr.rotate(textAngle); /* sign? */

    const justification = element.getAttribute("Justification") || DEFAULT_JUSTIFICATION;

    // Row distance
    const rowDistance = getRowDistance(strings, desiredHeight, width, extent, textAngle, font);

    const results: Konva.Text[] = [];
    strings.forEach((string, index) => {
        if (!string || string.trim() === '')
            return;
        // Measure text size
        const stringMetrics = measureText(string, font);
        const fontHeight = stringMetrics.actualBoundingBoxAscent; // + stringMetrics.fontBoundingBoxDescent;
        const scale = desiredHeight / fontHeight;
        const stringLength = stringMetrics.width * scale;

        const strTr = tr.copy();
        if (justification.startsWith("Right"))
            strTr.translate(-stringLength, 0.0);
        else if (justification.startsWith("Center"))
            strTr.translate(-0.5 * stringLength, 0.0);
        // else => justification.endsWith("Right")

        if (justification.endsWith("Bottom"))
            strTr.translate(0.0, desiredHeight);
        else if (justification.endsWith("Center"))
            strTr.translate(0.0, 0.5 * desiredHeight);
        // else => justification.startsWith("Top")

        strTr.translate(0.0, -rowDistance * index);

        strTr.scale(scale, -scale);

        const text = new Konva.Text({
            ...strTr.decompose(),
            text: string,
            fontSize: 100.0,
            fontFamily: font,
            fill: color!,
            ...DISABLE_LISTENING
        });
        results.push(text);
    });

    return results;
}

function getRowDistance(strings: string[], heightAttr: number, widthAttr: number, extent: Extent | undefined, textAngle: number, font: string): number {
    if (strings.length < 2)
        return 0;
    if (!extent)
        return heightAttr;

    let maxWidth = 0;
    strings.forEach((string) => {
        // Measure text size
        const stringMetrics = measureText(string, font);
        const fontHeight = stringMetrics.actualBoundingBoxAscent / 100.0; // + stringMetrics.fontBoundingBoxDescent;
        const scale = heightAttr / fontHeight;
        const stringLength = stringMetrics.width * scale / 100.0;
        if (stringLength > maxWidth)
            maxWidth = stringLength;
    });
    maxWidth = Math.max(maxWidth, widthAttr);

    const extentH = extent.maxY - extent.minY;
    const extentW = extent.maxX - extent.minX;
    let unrotatedExtentH = extentH;
    if (Math.abs(textAngle - Math.PI / 2) < 0.01 || Math.abs(textAngle - Math.PI * 3 / 2) < 0.01)
        unrotatedExtentH = extentW;
    else if (Math.abs(textAngle - Math.PI) < 0.01 || Math.abs(textAngle) < 0.01) {
        unrotatedExtentH = extentH;
    }
    else if (textAngle > 0 && textAngle < Math.PI / 2) {
        const a = textAngle;
        unrotatedExtentH = Math.abs((extentH - Math.sin(a) * maxWidth) / Math.sin(Math.PI / 2 - a));
    }
    else if (textAngle > Math.PI && textAngle < Math.PI * 3 / 2) {
        const a = textAngle - Math.PI;
        unrotatedExtentH = Math.abs((extentH - Math.sin(a) * maxWidth) / Math.sin(Math.PI / 2 - a));
    }
    else if (textAngle > Math.PI / 2 && textAngle < Math.PI) {
        const a = textAngle - Math.PI / 2;
        unrotatedExtentH = Math.abs((extentH - Math.cos(a) * maxWidth) / Math.cos(Math.PI / 2 - a));
    }
    else if (textAngle > Math.PI * 3 / 2 && textAngle < Math.PI * 2) {
        const a = textAngle - Math.PI * 3 / 2;
        unrotatedExtentH = Math.abs((extentH - Math.cos(a) * maxWidth) / Math.cos(Math.PI / 2 - a));
    }
    const lineSpace = (unrotatedExtentH - strings.length * heightAttr) / (strings.length - 1);
    return Math.max(heightAttr, heightAttr + lineSpace)
}

function renderLine(element: Element, listeningConfig: Konva.ShapeConfig): Konva.Shape {
    const presentation = readPresentation(element);

    const points = readCoordinates(element);

    let modifiedListening = listeningConfig;
    if (listeningConfig.listening === false && element.localName === 'CenterLine')
        modifiedListening = {
            listening: true,
            id: getId(element) || ""
        };

    return new Konva.Line({ ...presentation, points, ...modifiedListening });
}

function renderShape(element: Element, listeningConfig: Konva.ShapeConfig) {
    const presentation = readPresentation(element);
    const fill = getFillColor(element);
    const points = readCoordinates(element);
    return new Konva.Line({
        ...presentation,
        fill,
        points,
        ...listeningConfig,
        closed: true
    });
}

function renderTrimmedCurve(element: Element, listeningConfig: Konva.ShapeConfig, tr: Transform, primaryAxis: number, secondaryAxis: number, presentation: any): Konva.Shape {
    const aAngle = readNumericAttribute(element, 'StartAngle', 0) * Math.PI / 180.0;
    const bAngle = readNumericAttribute(element, 'EndAngle', 0) * Math.PI / 180.0;

    const ax = Math.cos(aAngle) * primaryAxis;
    const ay = Math.sin(aAngle) * secondaryAxis;

    const bx = Math.cos(bAngle) * primaryAxis;
    const by = Math.sin(bAngle) * secondaryAxis;

    const longArc = Math.abs(bAngle - aAngle) > Math.PI ? 1 : 0;
    const clockwise = bAngle > aAngle ? 1 : 0;

    return new Konva.Path({
        ...tr.decompose(),
        ...presentation,
        ...listeningConfig,
        data: `M ${ax},${ay} A${primaryAxis},${secondaryAxis} 0 ${longArc},${clockwise} ${bx},${by}`,
    });
}

function renderCircle(element: Element, listeningConfig: Konva.ShapeConfig): Konva.Shape {
    const presentation = readPresentation(element);

    const tr = getPositionAsKonvaTransform(element);
    const radius = readNumericAttribute(element, 'Radius', 0);
    const fill = getFillColor(element);

    if (element.parentElement && element.parentElement.localName === 'TrimmedCurve') {
        return renderTrimmedCurve(element.parentElement, listeningConfig, tr, radius, radius, presentation);
    }
    else {
        return new Konva.Circle({
            ...tr.getTranslation(),
            ...presentation,
            fill,
            ...listeningConfig,
            radius,
        });
    }
}

function renderEllipse(element: Element, listeningConfig: Konva.ShapeConfig): Konva.Shape {
    const presentation = readPresentation(element);

    const tr = getPositionAsKonvaTransform(element);
    const primaryAxis = readNumericAttribute(element, 'PrimaryAxis', 0);
    const secondaryAxis = readNumericAttribute(element, 'SecondaryAxis', 0);

    if (element.parentElement && element.parentElement.localName === 'TrimmedCurve') {
        return renderTrimmedCurve(element.parentElement, listeningConfig, tr, primaryAxis, secondaryAxis, presentation);
    }
    else {
        return new Konva.Ellipse({
            ...tr.decompose(),
            ...presentation,
            ...listeningConfig,
            radiusX: primaryAxis,
            radiusY: secondaryAxis,
        });
    }
}

export function getGraphics(element: Element,
    colorMode: number,
    notificationCallback: (message: string) => void,
    overrideStyle?: Konva.ShapeConfig,
    shapeGraphics: Map<string, Konva.Group> = new Map([])): Konva.Shape[] {
    const result: Konva.Shape[] = [];
    const consumer = (node: Konva.Shape) => result.push(node);
    renderProteusElement(overrideStyle ? () => overrideStyle : readPresentation, DISABLE_LISTENING, element, consumer, shapeGraphics, notificationCallback, colorMode);
    return result;
}

interface ProteusElementProps {
    element: Element;
    overrideStyle?: Konva.ShapeConfig;
    shapeGraphics?: Map<string, Konva.Group>;
    notificationCallback: (message: string) => void;
    colorMode: number;
}

export const ProteusElement: FunctionComponent<ProteusElementProps> = (props: ProteusElementProps) => {
    const {element, overrideStyle, shapeGraphics, notificationCallback, colorMode} = props;
    const groupRef: React.RefObject<Konva.Group> = useRef(null);

    useEffect(() => {
        const group = groupRef.current;
        if (group) {
            group.removeChildren();
            renderProteusElement(overrideStyle ? () => overrideStyle : readPresentation,
                DISABLE_LISTENING,
                element,
                (node: Konva.Shape) => group.add(node), 
                shapeGraphics ? shapeGraphics : new Map([]),
                notificationCallback,
                colorMode);
            group.getLayer()?.batchDraw();
        }
    }, [groupRef.current, element, overrideStyle]);

    return <Group ref={groupRef}
        listening={false}
        hitStrokeWidth={0}
    />;
}

export const DEFAULT_NOTIFICATION_CALLBACK = (message: string): void => {
    console.error(message);
};

function renderProteusElement(readPresentation: ReadPresentation, listeningConfig: Konva.ShapeConfig,
    element: Element, consumer: (node: Konva.Shape) => void, shapeGraphics: Map<string, Konva.Group> = new Map([]),
                                notificationCallback: (message: string) => void, colorMode: number) {
    switch (element.localName) {
        case 'PolyLine':
            consumer(renderPolyLine(element, listeningConfig));
            break;
        case 'Text':
            for (const shape of renderText(element, listeningConfig, notificationCallback, colorMode))
                consumer(shape);
            break;
        case 'CenterLine':
        case 'Line': {
            consumer(renderLine(element, listeningConfig));
        } break;
        case 'Circle':
            consumer(renderCircle(element, listeningConfig));
            break;
        case 'Ellipse':
            consumer(renderEllipse(element, listeningConfig));
            break;
        case 'Shape':
            consumer(renderShape(element, listeningConfig));
            break;
        case 'ProcessInstrumentationFunction':
        case 'ActuatingSystemComponent':
        case 'ProcessSignalGeneratingSystemComponent':
        case 'InformationFlow':
        case 'PropertyBreak':
        case 'InstrumentComponent':
        case 'PipingComponent':
        case 'ProcessInstrument':
        case 'Equipment':
        case 'PipeConnectorSymbol':
        case 'SignalConnectorSymbol':
        case 'SignalLine':
        case 'Label':
        case 'Component':
        case 'InsulationSymbol':
        case 'PipeFlowArrow':
        case 'PipeSlopeSymbol':
        case 'ScopeBubble':
        case 'Symbol':
        case 'PipeOffPageConnector':
        case 'InformationFlowOffPageConnector':
        case 'InstrumentationLoopFunction':
        case 'ProcessSignalGeneratingFunction':
        case 'Nozzle': {
            const childListeningConfig =
            {
                listening: true,
                id: getId(element) || listeningConfig.id
            };
            const componentName = getComponentName(element);
            if (componentName) {
                const shape = shapeGraphics.get(componentName);
                if (shape) {
                    // note: with the 'Scale' element this transform scales the lineWeight as well, which is probably not intended behaviour for Proteus?
                    // tried to set 'strokeScaleEnabled: false' for all shapes but this just caused more issues (stroke is not affected by zooming, shapes with small lineWeight cannot be seen at all)
                    const tr = new Konva.Transform();
                    tr.multiply(getKonvaTransform(element));
                    tr.multiply(shape.getTransform());
                    const clone: Konva.Shape = shape.clone({
                        ...tr.decompose(),
                    });
                    clone.getChildren().each((node) => {
                        if (node.getAttr('listening'))
                            node.setAttrs({ ...childListeningConfig });
                    });
                    consumer(clone);
                }
            }
            for (const child of Array.from(element.childNodes))
                if (child instanceof Element)
                    renderProteusElement(readPresentation, childListeningConfig, child, consumer, shapeGraphics, notificationCallback, colorMode);
        } break;
        case 'ShapeCatalogue':
        case 'GenericAttributes':
        case 'Extent':
        case 'Position':
        case 'Connection':
        case 'CrossPageConnection':
        case 'Presentation':
        case 'PersistentID':
        case 'ConnectionPoints':
        case 'NominalDiameter':
        case 'PlantInformation':
        case 'MaximumDesignTemperature':
        case 'PipeOffPageConnectorReference':
        case 'Association':
            // Skip these elements to speed up xml tree traversal
            break;
        default:
/*            console.log("Unkown element " + element.localName);
        case 'DrawingBorder':
        case 'PipingNetworkSegment':
        case 'PipingNetworkSystem':
        case 'PlantModel':
        case 'Drawing':*/
            element.childNodes.forEach((child) => {
                if (child instanceof Element) {
                    renderProteusElement(readPresentation, listeningConfig, child, consumer, shapeGraphics, notificationCallback, colorMode);
                }
            });
    }

}
