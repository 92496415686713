import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { UserState } from 'redux-oidc';
import Login from './Auth/Login';
import { ProjectList } from './Project/ProjectList';
import { StoreState } from './state';
import { asyncComponent } from './utils/AsyncComponent';
import { USE_PORTAL } from './utils/constants';

const AsyncProject = asyncComponent(() => import('./Project/Project'));

interface StatePropsFromStore {
    oidc: UserState;
}

type Props = StatePropsFromStore;

function ProtectedApplication(props: Props) {
  // @ts-ignore
  const projectList = (renderProps) => {
    const id_token = props.oidc.user ? props.oidc.user.id_token : '';
    return <ProjectList {...props} id_token={id_token} />
  };

  const projectListRoute = USE_PORTAL ? (null) : (<Route exact path="/" render={projectList} />);

  return props.oidc.user || !USE_PORTAL ? (
    <Switch>
      {projectListRoute}
      <Route path="/projects/:projectId" component={AsyncProject} />
    </Switch>
  ) : <Login />;
}

function mapStateToProps(state: StoreState): StatePropsFromStore {
    const { oidc } = state;
    return {
        oidc,
    };
}

export default connect(mapStateToProps)(ProtectedApplication);
