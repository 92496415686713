import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Input, Message, Table } from 'semantic-ui-react';
import { API_URL } from '../utils/constants';

interface Props {
    id_token: string;
}

export function ProjectList(props: Props) {
    const [projects, setProjects] = useState([] as string[]);
    const [projectName, setProjectName] = useState("");
    useEffect(() => {
        fetch(`${API_URL}/projects`, {
            headers: { Authorization: 'Bearer ' + props.id_token }
        }).then((response) => {
            response.json().then(doc => {
                setProjects(doc as string[]);
            }).catch((error) => console.log(error));
        }).catch((error) => console.log(error));
    }, []);
    return <Container>
        <Message info>
            This is a placehold project list that will be replaced by Portal application.
            Refresh to update!
        </Message>
        <Input value={projectName} onChange={(event, { value }) => setProjectName(value)} placeholder='Name of new or existing project' fluid label={
            <Button as='a' href={`projects/${projectName}/diagrams`} disabled={projectName === ''}>Go to</Button>}
        />
        <Header>List of current projects:</Header>
        <Table compact='very'><Table.Body>
            {projects.map(project => {
                return <Table.Row key={project}>
                    <Table.Cell>
                        <Link to={`projects/${project}/diagrams`}>{project}</Link>
                    </Table.Cell>
                    <Table.Cell collapsing><Button icon='trash' onClick={() => {
                        deleteProject(project);
                        setProjects(projects.filter(p => p !== project));
                    }}/></Table.Cell>
                </Table.Row>;
            })}
        </Table.Body></Table>
    </Container>;
}

function deleteProject(project: string) {
    fetch(`${API_URL}/projects/${project}`, {
        method: 'DELETE'
    })
    .catch((error) => console.log(error));
}
