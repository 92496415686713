import { connectRouter, RouterAction, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Action, applyMiddleware, combineReducers, createStore, Reducer } from 'redux';
import { BatchAction, enableBatching } from 'redux-batched-actions';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadUser, reducer as oidcReducer, UserState } from 'redux-oidc';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { ActionType } from 'typesafe-actions';
import { newGrpcMiddleware } from '../middleware/grpcMiddleware';
import userManager from '../utils/userManager';
import { OidcState, oidcStateReducer } from './auth';
import { diagramsActions, diagramsReducer, DiagramsState } from './diagrams';
import { modeActions, modeReducer, ModeState } from './mode';
import { diagramSelectionActions, diagramSelectionReducer, DiagramSelectionState } from './diagramSelection';
import { GrpcAction } from './grpc';
import { notificationActions, NotificationStore, notificationReducer } from './notifications';
import { projectActions, projectReducer, ProjectState } from './project';
import { typesActions, typesReducer, TypesState } from './types';
import { relatedEntitiesActions, RelatedEntitiesState, relatedEntitiesReducer } from './relatedEntities';
import { searchActions, SearchState, searchReducer } from './search';
import { InstrumentIndexViewState, instrumentIndexViewActions, instrumentIndexViewReducer } from './instrumentIndexView';
import { NotificationType } from './notifications';
import { patternEditorExpandersActions, patternEditorExpandersState, patternEditorExpandersStateReducer } from './patternEditorExpandersState'

const actions = {
    ...diagramsActions,
    ...diagramSelectionActions,
    ...projectActions,
    ...modeActions,
    ...notificationActions,
    ...relatedEntitiesActions,
    ...typesActions,
    ...searchActions,
    ...instrumentIndexViewActions,
    ...notificationActions,
    ...patternEditorExpandersActions
};

export type IStoreActions =
    // @ts-ignore
    | ActionType<typeof actions>
    | BatchAction
    // tslint:disable-next-line: no-any
    | GrpcAction<any, any>
    | RouterAction;

export const history = createBrowserHistory();

export interface StoreState {
    diagrams: DiagramsState;
    diagramSelection: DiagramSelectionState;
    mode: ModeState;
    project: ProjectState;
    relatedEntities: RelatedEntitiesState;
    router: RouterState;
    types: TypesState;
    oidc: UserState;
    oidcState: OidcState;
    search: SearchState;
    instrumentIndexView: InstrumentIndexViewState;
    notifications: NotificationStore;
    patternEditorExpanders: patternEditorExpandersState;
}

const reducer = combineReducers({
    diagrams: diagramsReducer,
    diagramSelection: diagramSelectionReducer,
    mode: modeReducer,
    project: projectReducer,
    types: typesReducer,
    relatedEntities: relatedEntitiesReducer,
    router: connectRouter(history),
    oidc: oidcReducer,
    oidcState: oidcStateReducer,
    search: searchReducer,
    instrumentIndexView: instrumentIndexViewReducer,
    notifications: notificationReducer,
    patternEditorExpanders: patternEditorExpandersStateReducer,
});

function logReduces<S>(reduce: Reducer<S>): Reducer<S> {
    return (state, action) => {
        return reduce(state, action);
    };
}

export default function configureStore(preloadedState?: StoreState) {
    const store = createStore(
        logReduces(enableBatching(reducer)),
        // preloadedState,
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                // ... other middlewares ...
                thunk as ThunkMiddleware<StoreState, Action>,
                //newApiMiddleware(),
                newGrpcMiddleware(),
            ),
        ),
    );
    loadUser(store, userManager);
    return store;
}
