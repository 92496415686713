import Konva from "konva";
import { getGraphics, getKonvaTransform } from "../proteus/ProteusRenderer";
import { Extent } from "./Extent";
import { ORIGO, Position } from "./Position";

const TRANSPARENT = '#00000000';

/**
 * Returns the ID attribute of the given element or null if ID is not defined.
 * @param element
 */
export function getId(element: Element): string | null {
    return element.getAttribute("ID");
}

/**
 * Return IDs of those elements that have ID defined.
 * @param elements
 */
export function getIds(elements: Element[]): string[] {
    return elements.map(getId).filter(id => id !== null) as string[];
}

/**
 * Returns the TagName of the element or null if TagName is not defined.
 * @param element
 */
export function getTagName(element: Element): string | null {
    return element.getAttribute("TagName");
}

/**
 * Returns the ComponentClass of the element or null if ComponentClass is not defined.
 * @param element
 */
export function getComponentClass(element: Element): string | null {
    return element.getAttribute("ComponentClass");
}

/**
 * Returns the ComponentName of the element or null if ComponentName is not defined.
 * @param element
 */
export function getComponentName(element: Element): string | null {
    return element.getAttribute("ComponentName");
}


/**
 * Returns the background color of the document's Drawing element or transparent if not defined.
 * @param element
 */
 export function getBackgroundColor(document: Document): string {
    const plantModel = firstChildByTagName(document.getRootNode() as Element, 'PlantModel');
    if (!plantModel)
        return TRANSPARENT;
    const drawing = firstChildByTagName(plantModel, 'Drawing');
    if (!drawing)
        return TRANSPARENT;
    const color = getColor(drawing);
    return color ? color : TRANSPARENT;
}

export function getColor(element: Element): string | null{
    const presentation = firstChildByTagName(element, 'Presentation');
    if (!presentation)
        return null;

    const r = readNumericAttribute(presentation, 'R', 0);
    const g = readNumericAttribute(presentation, 'G', 0);
    const b = readNumericAttribute(presentation, 'B', 0);

    return 'rgb(' + Math.floor(r * 255) + ',' + Math.floor(g * 255) + ',' + Math.floor(b * 255) + ')';
}

/**
 * Returns the first child element of the given element with the given tagName or undefined if no child matches.
 * @param element
 * @param tagName
 */
export function firstChildByTagName(element: Element, tagName: string): Element | undefined {
    let child = element.firstChild;
    while (child !== null) {
        if (child instanceof Element && child.nodeName === tagName)
            return child;
        child = child.nextSibling;
    }
    return undefined;
}

/**
 * Returns all child elements of the given element with the given tagName or empty list if no child matches.
 * @param element
 * @param tagName
 */
export function childrenByTagName(element: Element, tagName: string): Element[] {
    const children = [];
    let child = element.firstChild;
    while (child !== null) {
        if (child instanceof Element && child.nodeName === tagName)
            children.push(child);
        child = child.nextSibling;
    }
    return children;
}

/**
 * Reads the contents of Proteus Extent element
 * @param element
 */
export function readExtent(element: Element): Extent | undefined {
    const min = firstChildByTagName(element, "Min");
    if (min === undefined)
        return undefined;
    const max = firstChildByTagName(element, "Max");
    if (max === undefined)
        return undefined;

    return new Extent(
        readNumericAttribute(min, "X", 0),
        readNumericAttribute(min, "Y", 0),
        readNumericAttribute(max, "X", 0),
        readNumericAttribute(max, "Y", 0)
    );
}

/**
 * Returns the extent of the given element based on a child element with tag name Extent.
 */
export function getExtent(element: Element): Extent | undefined {
    const extent = firstChildByTagName(element, "Extent");
    if (extent === undefined)
        return undefined;
    return readExtent(extent);
}

export function getExtentFromDocument( document: Document): Extent | undefined {
    const root = document.getRootNode() as Element;
    if (root.firstChild === null)
        return undefined;
    const extent = getExtent(root.firstChild as Element);
    if (!extent)
        return getBoundingBox(root.firstChild as Element, readShapeExtents(document), true);
    return extent;
}

export function readNumericAttribute(element: Element, attribute: string, def: number): number {
    const attributeValue = element.getAttribute(attribute);
    return attributeValue
        ? parseFloat(attributeValue.replace(',', '.'))
        : def;
}

export function readCoordinates(element: Element): number[] {
    const coordinates = element.getElementsByTagName('Coordinate');
    if (!coordinates) {
        return [];
    }

    const points: number[] = [];
    for (let i = 0; i < coordinates.length; ++i) {
        points.push(readNumericAttribute(coordinates[i], 'X', 0));
        points.push(readNumericAttribute(coordinates[i], 'Y', 0));
    }
    return points;
}

export function isConnectionType(elementName: string): boolean {
    return elementName === 'SignalLine' || elementName === 'InformationFlow' || elementName === 'CenterLine';
}

export function isCrossPageConnectionType(elementName: string): boolean {
    return elementName === 'PipeConnectorSymbol' || elementName === 'SignalConnectorSymbol'
        || elementName === 'PipeOffPageConnector' || elementName === 'InformationFlowOffPageConnector';
}

export function getLocation(element: Element) {
    const location = firstChildByTagName(element, 'Location');
    if (location === undefined)
        return ORIGO;
    const x = readNumericAttribute(location, 'X', 0);
    const y = readNumericAttribute(location, 'Y', 0);
    return { x, y };
}

export function getUnitVector3(element: Element, elementName: string) {
    const child = firstChildByTagName(element, elementName);
    if (child === undefined)
        return undefined;
    let x = readNumericAttribute(child, 'X', 0);
    let y = readNumericAttribute(child, 'Y', 0);
    let z = readNumericAttribute(child, 'Z', 0);
    const len = Math.sqrt(x * x + y * y + z * z);
    if (len > 0) {
        x /= len;
        y /= len;
        z /= len;
    }
    return { x, y, z };
}

/**
 *
 * @param element Get content of Position element under the given element. Return (0,0), if no position found.
 */
export function getPosition(element: Element) {
    const position = firstChildByTagName(element, 'Position');
    if (position === undefined)
        return ORIGO;
    return getLocation(position);
}

export function getFillColor(element: Element) {
    if (element.getAttribute('Filled') !== null) {
        const fillColor = getColor(element);
        if (fillColor !== null)
            return fillColor;
    }
    return '#00000000';
}

export function getBoundingBox(element: Element, shapeExtents: Map<string, Extent> = new Map([]), includeChildComponent: boolean): Extent | undefined {
    const bb = Extent.empty();
    calculateBoundingBoxOfComponent(element, bb, shapeExtents, includeChildComponent);
    if (isFinite(bb.minX))
        return bb;
    else
        return undefined;
}

const skipDuringBoundingBoxCalculation = new Set([
    "GenericAttributes",
    "Presentation",
    "Extent",
    "Position"
]);

/**
 * Adds the extent of this node to the given bounding box.
 * TODO: This method exaggerate the extent of circle and ellipse arcs
 * because it ignores TrimmedCurve elements.
 * @param node
 * @param bb Bounding box to update
 */
function calculateBoundingBoxOfComponent(node: ChildNode, bb: Extent, shapeExtents: Map<string, Extent> = new Map([]), includeChildComponent: boolean) {
    if (!(node instanceof Element))
        return;

    // Shape from shape catalogue
    const componentName = getComponentName(node);
    if (componentName !== null) {
        const shapeExtent = shapeExtents.get(componentName);
        if (shapeExtent !== undefined && isFinite(shapeExtent.minX)) {
            const tr = getKonvaTransform(node);
            const transformedExtent = shapeExtent.clone();
            transformedExtent.transform(tr);
            bb.expandToIncludeExtent(transformedExtent);
        }
    }

    // Child elements
    node.childNodes.forEach(child => {
        if(node instanceof Element
            && !skipDuringBoundingBoxCalculation.has(child.nodeName)
            && !calculateBoundingBoxOfShape(child, bb)
            && includeChildComponent)
            calculateBoundingBoxOfComponent(child, bb, shapeExtents, true);
    });
}

function calculateBoundingBoxOfShape(node: ChildNode, bb: Extent) {
    switch (node.nodeName) {
        case 'Coordinate': {
            const element = node as Element;
            const x = readNumericAttribute(element, 'X', 0);
            const y = readNumericAttribute(element, 'Y', 0);
            bb.expandToIncludePoint(x, y);
            return true;
        }
        case 'Circle': {
            const element = node as Element;
            const position = getPosition(element);
            const radius = readNumericAttribute(element, 'Radius', 0);
            bb.expandToIncludeEllipse(position.x, position.y, radius, radius);
            return true;
        }
        case 'Ellipse': {
            const element = node as Element;
            const position = getPosition(element);
            const primaryAxis = readNumericAttribute(element, 'PrimaryAxis', 0);
            const secondaryAxis = readNumericAttribute(element, 'SecondaryAxis', 0);
            bb.expandToIncludeEllipse(position.x, position.y, primaryAxis, secondaryAxis);
            return true;
        }
        case 'TrimmedCurve':
        case 'PolyLine':
        case 'CenterLine':
        case 'Line':
        case 'Shape':
            node.childNodes.forEach(child => calculateBoundingBoxOfShape(child, bb));
            return true;
        default:
            return false;
    }
}

/**
 * Returns the content of all Coordinate children of the given element.
 * @param element
 */
export function getCoordinates(element: Element): Position[] {
    const coordinates: Position[] = [];
    element.childNodes.forEach(node => {
        if (node instanceof Element && node.tagName === 'Coordinate') {
            const coordinateElement = node as Element;
            coordinates.push({
                x: parseFloat(coordinateElement.getAttribute('X')!),
                y: parseFloat(coordinateElement.getAttribute('Y')!)
            });
        }
    });
    return coordinates;
}

/**
 * Returns the ShapeCatalogue of the given document or undefined if ShapeCatalogue is not defined.
 * @param document
 */
 export function getShapeCatalogue(document: Document): Element | undefined {
    const plantModel = firstChildByTagName(document.getRootNode() as Element, 'PlantModel');
    if (plantModel)
        return firstChildByTagName(plantModel, 'ShapeCatalogue');
    return undefined;
}


export function readShapeElements(model: Document): Map<string, Element> {
    const shapeCatalogueElement = getShapeCatalogue(model);
    const shapeMap: Map<string, Element> = new Map([]);
    if (!shapeCatalogueElement)
        return shapeMap;

    for (const child of Array.from(shapeCatalogueElement.childNodes)) {
        if (child instanceof Element) {
            const componentName = getComponentName(child);
            if (componentName !== null) {
                shapeMap.set(componentName, child);
            }
        }
    }
    return shapeMap;
}

export function readShapeExtents(model: Document): Map<string, Extent> {
    const shapeCatalogue = readShapeElements(model);
    const shapeExtents: Map<string, Extent> = new Map([]);
    shapeCatalogue.forEach((element, componentName) => {
        const extent = Extent.empty();
        element.childNodes.forEach(child => {
            calculateBoundingBoxOfShape(child, extent);
        });
        shapeExtents.set(componentName, extent);

    })
    return shapeExtents;
}

export function readShapeGraphics(model: Document, colorMode: number, notificationCallback: (message: string) => void): Map<string, Konva.Group> {
    const shapeCatalogue = readShapeElements(model);
    const shapeGraphics: Map<string, Konva.Group> = new Map([]);
    shapeCatalogue.forEach((element, componentName) => {
        const shapes = getGraphics(element, colorMode, notificationCallback);
        const group = new Konva.Group();
        shapes.forEach((shape) => group.add(shape));
        shapeGraphics.set(componentName, group);
    })
    return shapeGraphics;
}

export function getDescendantsWithId(node: Element, children: Element[] = []): Element[]{
    if (node.childNodes.length > 0) {
        for (let index = 0; index < node.children.length; index++) {
            const childNode = node.children.item(index);
            if (childNode && getId(childNode) !== null) {
                children.push(childNode)
                children.push(...getDescendantsWithId(childNode))
            }
        }
    }
    return children;
}
