import { push } from "connected-react-router";
import { parse } from "query-string";
import { createAction, getType } from "typesafe-actions";
import { IStoreActions, StoreState } from ".";
import { unsubscribed } from "./project";
import { ThunkDispatch, ThunkResult } from "./store-types";

export const updateModeByQueryString = createAction(
    "mode/updateModeByQueryString",
    (resolve) => {
        return (queryString: string) => resolve(queryString);
    }
);

export const setModeState = createAction("mode/setModeState", (resolve) => {
    return (modeState: ModeState) => resolve(modeState);
});

export const modeActions = {
    updateModeByQueryString,
    setModeState,
};

// State

export enum Mode {
    Types,
    ConfigureTypes,
    Relations,
    ConfigureRelations,
    Patterns,
    ConfigurePatterns,
    Conflicts,
    InstrumentIndex,
    TagList,
    SearchResults,
}

export interface ModeState {
    mode: Mode;
    lastDiagramMode: Mode;
    diagramId?: string;
    typeId?: string;
    relationId?: string;
    patternId?: string;
    conflictId?: string;
}

const initialState: ModeState = {
    mode: Mode.Patterns,
    lastDiagramMode: Mode.Patterns,
};

const diagramModeDescs: [Mode, string, string][] = [
    [Mode.Types, "type", "typeId"],
    [Mode.ConfigureTypes, "configureType", "typeId"],
    [Mode.Relations, "relation", "relationId"],
    [Mode.ConfigureRelations, "configureRelation", "relationId"],
    [Mode.Patterns, "pattern", "patternId"],
    [Mode.ConfigurePatterns, "configurePattern", "patternId"],
    [Mode.Conflicts, "conflict", "conflictId"],
];

const otherModeDescs: [Mode, string][] = [
    [Mode.TagList, "tagList"],
    [Mode.InstrumentIndex, "instrumentIndex"],
    [Mode.SearchResults, "searchResults"],
];

// Reducer
export function modeReducer(
    state = initialState,
    action: IStoreActions
): ModeState {
    if (action.type === getType(updateModeByQueryString)) {
        const queryString = action.payload;

        state = Object.assign({}, state);

        const props = parse(queryString);

        const diagramId = props["diagram"];
        if (typeof diagramId === "string") state.diagramId = diagramId;

        for (const modeDesc of diagramModeDescs) {
            const id = props[modeDesc[1]];
            if (id !== undefined) {
                state.lastDiagramMode = state.mode = modeDesc[0];
                if (typeof id === "string" && id.length > 0) {
                    (state as any)[modeDesc[2]] = id;
                    return state;
                }
            }
        }

        for (const modeDesc of otherModeDescs) {
            if (props[modeDesc[1]] !== undefined) {
                state.mode = modeDesc[0];
                return state;
            }
        }

        return state;
    } else if (action.type === getType(setModeState)) {
        return action.payload;
    } else if (action.type === getType(unsubscribed)) {
        return initialState;
    } else return state;
}

// Derived actions

function createURL(projectId: string, modeState: ModeState) {
    const {
        mode,
        typeId,
        relationId,
        patternId,
        conflictId,
        diagramId,
    } = modeState;
    let url = `/projects/${projectId}?`;
    switch (mode) {
        case Mode.Types:
            url = `${url}type=${typeId || ""}`;
            break;
        case Mode.ConfigureTypes:
            url = `${url}configureType=${typeId || ""}`;
            break;
        case Mode.Relations:
            url = `${url}relation=${relationId || ""}`;
            break;
        case Mode.ConfigureRelations:
            url = `${url}configureRelation=${relationId || ""}`;
            break;
        case Mode.Patterns:
            url = `${url}pattern=${patternId || ""}`;
            break;
        case Mode.ConfigurePatterns:
            url = `${url}configurePattern=${patternId || ""}`;
            break;
        case Mode.Conflicts:
            url = `${url}conflict=${conflictId || ""}`;
            break;
        case Mode.InstrumentIndex:
            url = `${url}instrumentIndex=`;
            break;
        case Mode.SearchResults:
            url = `${url}searchResults=`;
            break;
        case Mode.TagList:
            url = `${url}tagList=`;
            break;
    }
    if (diagramId) url = `${url}&diagram=${diagramId}`;
    return url;
}

export const updateMode = (
    update: (modeState: ModeState) => ModeState
): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project, mode } = getState();
        const { projectId } = project;
        const newModeState = update(mode);
        const url = createURL(projectId, newModeState);
        dispatch(push(url));
    };
};

export const isDiagramMode = (mode: Mode) =>
    mode === Mode.Types ||
    mode === Mode.Relations ||
    mode === Mode.Patterns ||
    mode === Mode.ConfigureTypes ||
    mode === Mode.ConfigureRelations ||
    mode === Mode.ConfigurePatterns ||
    mode === Mode.Conflicts;

export const showDiagram = (diagramId: string): ThunkResult<void> =>
    updateMode((mode) => {
        if (mode.mode !== mode.lastDiagramMode && mode.mode !== Mode.Conflicts)
            mode = { ...mode, mode: mode.lastDiagramMode };
        return { ...mode, diagramId };
    });

export const showDiagrams = (): ThunkResult<void> =>
    updateMode((mode) => {
        return { ...mode, mode: mode.lastDiagramMode || Mode.Types };
    });

export const showType = (typeId?: string): ThunkResult<void> =>
    updateMode((mode) => ({
        ...mode,
        mode: mode.mode === Mode.ConfigureTypes ? mode.mode : Mode.Types,
        typeId: typeId || mode.typeId,
    }));

export const showRelation = (relationId?: string): ThunkResult<void> =>
    updateMode((mode) => ({
        ...mode,
        mode:
            mode.mode === Mode.ConfigureRelations ? mode.mode : Mode.Relations,
        relationId: relationId || mode.relationId,
    }));

export const showPattern = (patternId?: string): ThunkResult<void> =>
    updateMode((mode) => ({
        ...mode,
        mode: mode.mode === Mode.ConfigurePatterns ? mode.mode : Mode.Patterns,
        patternId: patternId || mode.patternId,
    }));

export const showConflict = (
    conflictId?: string,
    diagramId?: string
): ThunkResult<void> =>
    updateMode((mode) => ({
        ...mode,
        mode: Mode.Conflicts,
        conflictId: conflictId || mode.conflictId,
        diagramId: diagramId || mode.diagramId,
    }));

export const showTagList = (): ThunkResult<void> =>
    updateMode((mode) => ({
        ...mode,
        mode: Mode.TagList,
    }));

export const showInstrumentIndex = (): ThunkResult<void> =>
    updateMode((mode) => ({
        ...mode,
        mode: Mode.InstrumentIndex,
    }));

export const showSearchResults = (): ThunkResult<void> =>
    updateMode((mode) => ({
        ...mode,
        mode: Mode.SearchResults,
    }));

function switchConfig(mode: Mode, configure: boolean): Mode {
    if (configure)
        switch (mode) {
            case Mode.Types:
                return Mode.ConfigureTypes;
            case Mode.Relations:
                return Mode.ConfigureRelations;
            case Mode.Patterns:
                return Mode.ConfigurePatterns;
        }
    else
        switch (mode) {
            case Mode.ConfigureTypes:
                return Mode.Types;
            case Mode.ConfigureRelations:
                return Mode.Relations;
            case Mode.ConfigurePatterns:
                return Mode.Patterns;
        }

    return mode;
}

export const enableConfigure = (configure: boolean): ThunkResult<void> =>
    updateMode((mode) => ({
        ...mode,
        mode: switchConfig(mode.mode, configure),
    }));
