import { grpc } from '@improbable-eng/grpc-web';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { createAction, getType } from 'typesafe-actions';
import { IStoreActions, StoreState } from ".";
import { ObjectType, ObjectTypeMap, ProjectStatusEvent } from '../proto/controlStatusEvents_pb';
import { CalculatePatternSuggestionsRequest, CalculateTypeSuggestionsRequest, CreateObjectRequest, DuplicatePatternRequest, DuplicatePatternResponse, JSError, ObjectId, ProjectStatusRequest, RemoveObjectRequest, UpdateObjectRequest, UpdateTypeExamplesRequest, ValidateRequest, ValidateResponse } from '../proto/control_pb';
import { ControlProject } from '../proto/control_pb_service';
import userManager from '../utils/userManager';
import { GrpcActionPayload, grpcRequest } from './grpc';
import { stdOnError } from './grpcutils';
import { showErrorNotification } from './notifications';
import { AvailableAttributes, Conflict, Diagram, fixConflict, fixPattern, fixPlantItemIndex, fixRelation, fixTargetColumns, fixType, InstrumentIndexColumns, InstrumentIndexFile, Pattern, PatternInstance, PatternRelation, PatternSuggestion, PatternType, PatternTypeSearch, PlantItemIndex, Problems, ProjectProperty, Status, TagListEntry, TargetColumns, TypeExamples, TypeSuggestion } from './projectTypes';
import { ThunkDispatch, ThunkResult } from './store-types';

// Actions

export const receivedProjectStatusEvent = createAction('project/receivedProjectStatusEvent', (resolve) => {
    return (event: ProjectStatusEvent) => resolve(event);
});

export const subscribed = createAction('project/subscribed', (resolve) => {
    return (projectId: string, subscription: grpc.Request) => resolve({ projectId, subscription });
});

export const unsubscribed = createAction('project/unsubscribed', (resolve) => {
    return (projectId: string) => resolve(projectId);
});

export const projectActions = {
    receivedProjectStatusEvent,
    subscribed,
    unsubscribed
};

// State
export type ProjectCollection<T> = Map<string, T>;
export type ObjectTypeId = ObjectTypeMap[keyof ObjectTypeMap];

export function extractSingletonValue<T>(collection: ProjectCollection<T>): T | null {
    return collection.first(null);
}

export interface ProjectState {
    projectId: string,
    subscription?: grpc.Request,
    diagrams: ProjectCollection<Diagram>,
    types: ProjectCollection<PatternType>,
    relations: ProjectCollection<PatternRelation>,
    patterns: ProjectCollection<Pattern>,
    availableAttributes: ProjectCollection<AvailableAttributes>,
    typeSearch: ProjectCollection<PatternTypeSearch>,
    patternInstances: ProjectCollection<PatternInstance>,
    plantItemIndices: ProjectCollection<PlantItemIndex>,
    conflicts: ProjectCollection<Conflict>,
    targetColumns: ProjectCollection<TargetColumns>,
    problems: ProjectCollection<Problems>,
    tagList: ProjectCollection<TagListEntry>,
    typeExamples: ProjectCollection<TypeExamples>,
    typeSuggestions: ProjectCollection<TypeSuggestion>,
    instrumentIndexFiles: ProjectCollection<InstrumentIndexFile>,
    instrumentIndexColumns: ProjectCollection<InstrumentIndexColumns>,
    status: ProjectCollection<Status>,
    patternSuggestions: ProjectCollection<PatternSuggestion>,
    projectProperties: ProjectCollection<ProjectProperty>,

    // Indices
    conflictsByInstance: Map<string, Conflict>,
    patternInstancesByDiagram: Map<string, ProjectCollection<PatternInstance>>
}

interface ProjectStateAny {
    [id: string]: ProjectCollection<any>
}

const initialState: ProjectState = {
    projectId: '',
    diagrams: Map<string,Diagram>(),
    types: Map<string,PatternType>(),
    relations: Map<string,PatternRelation>(),
    patterns: Map<string,Pattern>(),
    availableAttributes: Map<string,AvailableAttributes>(),
    typeSearch: Map<string,PatternTypeSearch>(),
    patternInstances: Map<string,PatternInstance>(),
    plantItemIndices: Map<string,PlantItemIndex>(),
    conflicts: Map<string,Conflict>(),
    targetColumns: Map<string,TargetColumns>(),
    problems: Map<string,Problems>(),
    tagList: Map<string,TagListEntry>(),
    typeExamples: Map<string,TypeExamples>(),
    typeSuggestions: Map<string,TypeSuggestion>(),
    instrumentIndexFiles: Map<string,InstrumentIndexFile>(),
    instrumentIndexColumns: Map<string,InstrumentIndexColumns>(),
    status: Map<string,Status>(),
    patternSuggestions: Map<string,PatternSuggestion>(),
    projectProperties: Map<string,ProjectProperty>(),

    // Indices
    conflictsByInstance: Map<string, Conflict>(),
    patternInstancesByDiagram: Map<string, ProjectCollection<PatternInstance>>()
};

// Reducer
const objectTypeToField: { [id: number]: string } = {
    [ObjectType.DIAGRAM]: "diagrams",
    [ObjectType.TYPE]: "types",
    [ObjectType.RELATION]: "relations",
    [ObjectType.PATTERN]: "patterns",
    [ObjectType.AVAILABLEATTRIBUTES]: "availableAttributes",
    [ObjectType.TYPESEARCH]: "typeSearch",
    [ObjectType.PATTERNINSTANCE]: "patternInstances",
    [ObjectType.PLANTITEMINDEX]: "plantItemIndices",
    [ObjectType.CONFLICT]: "conflicts",
    [ObjectType.TARGETCOLUMNS]: "targetColumns",
    [ObjectType.PROBLEMS]: "problems",
    [ObjectType.TAGLIST]: "tagList",
    [ObjectType.TYPEEXAMPLES]: "typeExamples",
    [ObjectType.TYPESUGGESTION]: "typeSuggestions",
    [ObjectType.INSTRUMENTINDEXFILE]: "instrumentIndexFiles",
    [ObjectType.INSTRUMENTINDEXCOLUMNS]: "instrumentIndexColumns",
    [ObjectType.STATUS]: "status",
    [ObjectType.PATTERNSUGGESTION]: "patternSuggestions",
    [ObjectType.PROJECTPROPERTY]: "projectProperties",
};

const objectFixer: { [id: number]: (object: any) => any } = {
    [ObjectType.TYPE]: fixType,
    [ObjectType.RELATION]: fixRelation,
    [ObjectType.PATTERN]: fixPattern,
    [ObjectType.PLANTITEMINDEX]: fixPlantItemIndex,
    [ObjectType.CONFLICT]: fixConflict,
    [ObjectType.TARGETCOLUMNS]: fixTargetColumns
};

const objectComparator: { [id: number]: (a: any, b: any) => boolean } = {
};

interface IndexUpdater {
    insert: (state: ProjectState, object: any) => void;
    remove: (state: ProjectState, object: any) => void;
}

export const emptyPatternInstances: ProjectCollection<PatternInstance> = Map<string,PatternInstance>();

const indexUpdaters: { [id: number]: IndexUpdater } = {
    [ObjectType.CONFLICT]: {
        insert: (state, object) => {
            const conflict = object as Conflict;
            state.conflictsByInstance = conflict.instances.reduce(
                (map, ref) => map.set(ref.instanceId, conflict),
                state.conflictsByInstance
            );
        },
        remove: (state, object) => {
            const conflict = object as Conflict;
            state.conflictsByInstance = conflict.instances.reduce(
                (map, ref) => map.remove(ref.instanceId),
                state.conflictsByInstance
            );
        },
    },
    [ObjectType.PATTERNINSTANCE]: {
        insert: (state, object) => {
            const instance = object as PatternInstance;
            for (const diagram of instance.containingDiagrams) {
                state.patternInstancesByDiagram = state.patternInstancesByDiagram.update(diagram, emptyPatternInstances,
                    instances => instances.set(instance.id, instance));
            }
        },
        remove: (state, object) => {
            const instance = object as PatternInstance;
            for (const diagram of instance.containingDiagrams) {
                state.patternInstancesByDiagram = state.patternInstancesByDiagram.update(diagram,
                    instances => instances === undefined ? Map<string,PatternInstance>() : instances.remove(instance.id));
            }
        },
    }
};

export function projectReducer(state = initialState, action: IStoreActions): ProjectState {
    if (action.type === getType(receivedProjectStatusEvent)) {
        const statusEvent = action.payload;
        switch (statusEvent.getEventCase()) {
            case ProjectStatusEvent.EventCase.INSERT: {
                const insert = statusEvent.getInsert()!;
                const id = insert.getId();
                const objectType = insert.getObjecttype();
                const fieldName = objectTypeToField[objectType];
                //console.log("received", fieldName, id, insert.getObject().length);
                const oldCollection = (state as any as ProjectStateAny)[fieldName];
                let document = JSON.parse(insert.getObject());
                document.id = id;

                const fixer = objectFixer[objectType];
                if (fixer)
                    document = fixer(document);

                /*const comparator = objectComparator[objectType];
                if(comparator) {
                    const oldDocument = oldCollection.get(id);
                    if(oldDocument && comparator(oldDocument, document))
                        return state;
                }*/

                const newState = {
                    ...state,
                    [fieldName]: oldCollection.set(id, document)
                };

                const indexUpdater = indexUpdaters[objectType];
                if (indexUpdater)
                    indexUpdater.insert(newState, document);

                return newState;
            }
            case ProjectStatusEvent.EventCase.DELETE: {
                const del = statusEvent.getDelete()!;
                const id = del.getId();
                const objectType = del.getObjecttype();
                const fieldName = objectTypeToField[objectType];
                const oldCollection = (state as any as ProjectStateAny)[fieldName];
                const oldObject = oldCollection.get(id);
                if (typeof oldObject === 'undefined')
                    return state;

                const newState = {
                    ...state,
                    [fieldName]: oldCollection.remove(id)
                };

                const indexUpdater = indexUpdaters[objectType];
                if (indexUpdater)
                    indexUpdater.remove(newState, oldObject);

                return newState;
            }
            case ProjectStatusEvent.EventCase.PING:
            default:
                return state;
        }
    }
    else if (action.type === getType(subscribed)) {
        if (state.subscription)
            state.subscription.close();

        const { projectId, subscription } = action.payload;
        return {
            ...initialState,
            projectId, subscription
        };
    }
    else if (action.type === getType(unsubscribed)) {
        if (state.projectId === action.payload)
            return initialState;
        else
            // We have already subscribed to another project
            return state;
    }
    else
        return state;
}

// Selectors

export function patternInstancesByDiagramSelector(diagramId: string): (state: StoreState) => ProjectCollection<PatternInstance> {
    return (state: StoreState) =>
        state.project.patternInstancesByDiagram.get(diagramId) || emptyPatternInstances;
}

export const typesHaveProblemsSelector = createSelector(
    (state: StoreState) => state.project.problems,
    (state: StoreState) => state.project.types,
    (problems, types) => problems.some(ps => types.has(ps.id))
);

export const relationsHaveProblemsSelector = createSelector(
    (state: StoreState) => state.project.problems,
    (state: StoreState) => state.project.relations,
    (problems, relations) => problems.some(ps => relations.has(ps.id))
);

export const patternsHaveProblemsSelector = createSelector(
    (state: StoreState) => state.project.problems,
    (state: StoreState) => state.project.patterns,
    (problems, patterns) => problems.some(ps => patterns.has(ps.id))
);

export const thereAreProblemsSelector = (state: StoreState) => !state.project.problems.isEmpty();

// Derived actions

const INITIAL_RETRY_DELAY = 1000.0;
const RETRY_DELAY_MULTIPLIER = 2.0;
const MAX_RETRY_DELAY = 30000.0;

export const subscribe = (projectId: string, retryDelay?: number): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {
        const request = new ProjectStatusRequest();
        request.setProjectid(projectId);

        const grpcAction: GrpcActionPayload<ProjectStatusRequest, ProjectStatusEvent> = {
            methodDescriptor: ControlProject.ProjectStatus,
            onMessage: (event) => receivedProjectStatusEvent(event),
            onStart: (stream) => subscribed(projectId, stream),
            onError: (code, msg) => {
                dispatch(unsubscribed(projectId));
                if (code === grpc.Code.Unknown) {
                    const actualRetryDelay = retryDelay===undefined ? INITIAL_RETRY_DELAY : Math.min(retryDelay, MAX_RETRY_DELAY);
                    setTimeout(() => dispatch(subscribe(projectId, actualRetryDelay*RETRY_DELAY_MULTIPLIER)), actualRetryDelay);
                }
                if (code === grpc.Code.PermissionDenied) {
                    // let's see if we can recalculate token permissions
                    const triedAlready = localStorage.getItem('reauthenticated');
                    if (triedAlready !== 'true') {
                        localStorage.setItem('reauthenticated', 'true');
                        userManager.signinRedirect();
                    }
                }
                return stdOnError(dispatch, 'Project Change Listening')(code, msg);
            },
            onEnd: (code) => {
                dispatch(unsubscribed(projectId));
                if (code === grpc.Code.OK) {
                    dispatch(showErrorNotification(
                        'Project Change Stream Finished',
                        'The project might have been removed.'
                    ));
                }
            },
            batch: true,
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const unsubscribe = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        if (!project.subscription)
            return;
        project.subscription.close();
        dispatch(unsubscribed(project.projectId));
    };
};

export const createObject = (objectType: ObjectTypeId, object: any, cb?: (id: string) => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new CreateObjectRequest();
        request.setProjectid(projectId);
        request.setObjecttype(objectType);
        request.setObject(JSON.stringify(object));

        const grpcAction: GrpcActionPayload<CreateObjectRequest, ObjectId> = {
            methodDescriptor: ControlProject.CreateObject,
            onError: (code, msg) => stdOnError(dispatch, 'Creating object')(code, msg),
            onMessage: cb && (entityId => cb(entityId.getId())),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const updateObject = (objectType: ObjectTypeId, objectId: string, object: any, cb?: () => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new UpdateObjectRequest();
        request.setProjectid(projectId);
        request.setObjectid(objectId);
        request.setObjecttype(objectType);
        request.setObject(JSON.stringify(object));

        const grpcAction: GrpcActionPayload<UpdateObjectRequest, Empty> = {
            methodDescriptor: ControlProject.UpdateObject,
            onError: (code, msg) => stdOnError(dispatch, 'Updating object')(code, msg),
            onMessage: cb,
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const deleteObject = (objectType: ObjectTypeId, objectId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new RemoveObjectRequest();
        request.setProjectid(projectId);
        request.setObjecttype(objectType);
        request.setObjectid(objectId);

        const grpcAction: GrpcActionPayload<RemoveObjectRequest, Empty> = {
            methodDescriptor: ControlProject.RemoveObject,
            onError: (code, msg) => stdOnError(dispatch, 'Removing object')(code, msg),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const createType = (object: PatternType, cb?: (id: string) => void): ThunkResult<void> => {
    return createObject(ObjectType.TYPE, object, cb);
}

export const updateType = (objectId: string, object: PatternType, cb?: () => void): ThunkResult<void> => {
    return updateObject(ObjectType.TYPE, objectId, object, cb);
}

export const deleteType = (objectId: string): ThunkResult<void> => {
    return deleteObject(ObjectType.TYPE, objectId);
}

export const createRelation = (object: PatternRelation, cb?: (id: string) => void): ThunkResult<void> => {
    return createObject(ObjectType.RELATION, object, cb);
}

export const updateRelation = (objectId: string, object: PatternRelation, cb?: () => void): ThunkResult<void> => {
    return updateObject(ObjectType.RELATION, objectId, object, cb);
}

export const deleteRelation = (objectId: string): ThunkResult<void> => {
    return deleteObject(ObjectType.RELATION, objectId);
}

export const createPattern = (object: Pattern, cb?: (id: string) => void): ThunkResult<void> => {
    return createObject(ObjectType.PATTERN, object, cb);
}

export const updatePattern = (objectId: string, object: Pattern, cb?: () => void): ThunkResult<void> => {
    return updateObject(ObjectType.PATTERN, objectId, object, cb);
}

export const deletePattern = (objectId: string): ThunkResult<void> => {
    return deleteObject(ObjectType.PATTERN, objectId);
}

export const duplicatePattern = (patternId: string, cb?: (newPatternId: string) => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new DuplicatePatternRequest();
        request.setProjectid(projectId);
        request.setPatternid(patternId);

        const grpcAction: GrpcActionPayload<DuplicatePatternRequest, DuplicatePatternResponse> = {
            methodDescriptor: ControlProject.DuplicatePattern,
            onMessage: cb && (response => cb(response.getNewpatternid())),
            onError: (code, msg) => stdOnError(dispatch, 'Duplicating pattern')(code, msg),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
}

export const updateTargetColumns = (object: TargetColumns, cb?: () => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        // There is just one possible key
        const entry = getState().project.targetColumns.keys().next();
        if (!entry.done)
            return dispatch(updateObject(ObjectType.TARGETCOLUMNS, entry.value, object, cb));
    };
}

export const updateTypeExamples = (typeId: string, examples: TypeExamples): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new UpdateTypeExamplesRequest();
        request.setProjectid(projectId);
        request.setTypeid(typeId);
        request.setPositiveList(examples.positive);
        request.setNegativeList(examples.negative);

        const grpcAction: GrpcActionPayload<UpdateTypeExamplesRequest, Empty> = {
            methodDescriptor: ControlProject.UpdateTypeExamples,
            onError: (code, msg) => stdOnError(dispatch, 'Updating type examples')(code, msg),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const calculateTypeSuggestions = (typeId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new CalculateTypeSuggestionsRequest();
        request.setProjectid(projectId);
        request.setTypeid(typeId);

        const grpcAction: GrpcActionPayload<CalculateTypeSuggestionsRequest, Empty> = {
            methodDescriptor: ControlProject.CalculateTypeSuggestions,
            onError: (code, msg) => stdOnError(dispatch, 'Calculating type suggestions')(code, msg),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const calculatePatternSuggestions = (patternId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new CalculatePatternSuggestionsRequest();
        request.setProjectid(projectId);
        request.setPatternid(patternId);

        const grpcAction: GrpcActionPayload<CalculatePatternSuggestionsRequest, Empty> = {
            methodDescriptor: ControlProject.CalculatePatternSuggestions,
            onError: (code, msg) => stdOnError(dispatch, 'Calculating pattern suggestions')(code, msg),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const setProperty = (key: string, value: any): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const prop = project.projectProperties.find(prop => prop.key === key);

        if(prop)
            dispatch(updateObject(ObjectType.PROJECTPROPERTY, prop.id, {key, value}));
        else
            dispatch(createObject(ObjectType.PROJECTPROPERTY, {key, value}));
    };
};

export const validate = (target: string, callback: (errors: JSError.AsObject[]) => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new ValidateRequest();
        request.setProjectid(projectId);
        request.setTarget(target);

        const grpcAction: GrpcActionPayload<ValidateRequest, ValidateResponse> = {
            methodDescriptor: ControlProject.Validate,
            onMessage: msg => {
                callback(msg.getErrorsList().map(error => error.toObject()));
            },
            onError: (code, msg) => stdOnError(dispatch, 'Validating ' + target)(code, msg),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};
