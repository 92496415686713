import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Layer, Rect } from 'react-konva';
import { DiagramDragEventHandler, DiagramEventHandler, DiagramPosition, DragFinishedEvent, DragMovedEvent, DragStartedEvent } from './Events';
import { KonvaLineStyle, scaleLineStyle } from './KonvaStyle';
import { ViewportContext, InternalDiagramEventHandler } from './Viewport';

class BoxSelectionState {
    setBox: (box: [DiagramPosition, DiagramPosition] | undefined) => void;
    internalEventHandler: InternalDiagramEventHandler;

    constructor(setBox: (box: [DiagramPosition, DiagramPosition] | undefined) => void, internalEventHandler: InternalDiagramEventHandler) {
        this.setBox = setBox;
        this.internalEventHandler = internalEventHandler;
    }

    onDragStarted = (startEvent: DragStartedEvent): DiagramDragEventHandler | undefined => {
        return {
            onDragMoved: (event: DragMovedEvent) => {
                if (this.setBox)
                    this.setBox([startEvent.startPosition, event.position]);
            },
            onDragFinished: (event: DragFinishedEvent) => {
                if (this.internalEventHandler.onBoxSelection)
                    this.internalEventHandler.onBoxSelection({
                        altKey: startEvent.altKey,
                        ctrlKey: startEvent.ctrlKey,
                        shiftKey: startEvent.shiftKey,
                        startPosition: startEvent.startPosition,
                        endPosition: event.endPosition,
                        scale: event.scale
                    });
                if (this.setBox)
                    this.setBox(undefined);
            },
            onDragCancelled: () => {
                if (this.setBox)
                    this.setBox(undefined);
            }
        };
    };

    public eventHandler: DiagramEventHandler = { onDragStarted: this.onDragStarted };
}

interface Props {
    lineStyle: KonvaLineStyle;
}

export function BoxSelectionLayer(props: Props) {
    const { lineStyle } = props;
    const [box, setBox] = useState(undefined as [DiagramPosition, DiagramPosition] | undefined);
    const { scale, registerEventHandler, internalEventHandler } = useContext(ViewportContext);
    const state = useMemo(() => new BoxSelectionState(setBox, internalEventHandler), []);
    registerEventHandler(() => state.eventHandler, []);
    let rect = null;
    if (box) {
        const scaledLineStyle = scaleLineStyle(scale, lineStyle);
        const minX = Math.min(box[0][0], box[1][0]);
        const maxX = Math.max(box[0][0], box[1][0]);
        const minY = Math.min(box[0][1], box[1][1]);
        const maxY = Math.max(box[0][1], box[1][1]);
        rect = <Rect {...scaledLineStyle}
            x={minX} y={minY} width={maxX - minX} height={maxY - minY}
        />;
    }
    return <Layer>
        {rect}
    </Layer>;
}

BoxSelectionLayer.defaultProps = {
    lineStyle: {
        stroke: 'green',
        strokeWidth: 1,
        dash: [4, 4],
    }
};
