import produce from 'immer';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { createAction, getType } from 'typesafe-actions';
import { v4 as uuid } from 'uuid';
import { USE_PORTAL } from '../utils/constants';
import { StoreState, IStoreActions } from './index';
import { ThunkDispatch, ThunkResult } from './store-types';

const maxNotifications = 100;

const notificationShow = createAction('NOTIFICATION_SHOW', (resolve) => {
    return (notification: Notification) => resolve(notification);
});

const notificationDismiss = createAction('NOTIFICATION_DISMISS', (resolve) => {
    return (notificationId: string) => resolve(notificationId);
});

const notificationClear = createAction('NOTIFICATION_CLEAR', (resolve) => {
    return (notificationId: string) => resolve(notificationId);
});

export enum NotificationType {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export interface Notification {
    date: Date;
    username: string;
    header: string;
    message: string;
    icon: SemanticICONS;
    type: NotificationType;

    dismissAfter: number;
    id: string;
}

export function showInfoNotification(header: string, message: string, icon?: SemanticICONS): ThunkResult<void> {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const username = USE_PORTAL ? getState().oidc.user!.profile.email! : '';
        dispatch(showNotification({
            date: new Date(),
            header,
            icon: icon ? icon : 'info',
            message,
            type: NotificationType.INFO,
            username,
            id: uuid(),
            dismissAfter: 5000,
        }));
    };
}

export function showWarningNotification(header: string, message: string): ThunkResult<void> {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const username = USE_PORTAL ? getState().oidc.user!.profile.email! : '';
        dispatch(showNotification({
            date: new Date(),
            header,
            icon: 'warning',
            message,
            type: NotificationType.WARNING,
            username,
            id: uuid(),
            dismissAfter: 5000,
        }));
    };
}

export function showErrorNotification(header: string, message: string): ThunkResult<void> {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const username = USE_PORTAL ? getState().oidc.user!.profile.email! : '';
        dispatch(showNotification({
            date: new Date(),
            header,
            icon: 'exclamation',
            message,
            type: NotificationType.ERROR,
            username,
            id: uuid(),
            dismissAfter: 5000,
        }));
    };
}

export function showNotification(notification: Notification): ThunkResult<string> {
    return (dispatch: ThunkDispatch): string => {

        dispatch(notificationShow(notification));
        setTimeout(
            () => {
                dispatch(notificationDismiss(notification.id));
            },
            notification.dismissAfter
        );
        return notification.id;
    };
}

export const notificationActions = {
    notificationClear,
    notificationDismiss,
    notificationShow,
};

export interface NotificationStoreActive {
    [id: string]: Notification;
}
export interface NotificationStorePermanent extends Array<Notification>{}

export interface NotificationStore {
    active: NotificationStoreActive;
    permanent: NotificationStorePermanent;
}
const initialState: NotificationStore = {active: {}, permanent: []}
export function notificationReducer(state: NotificationStore = initialState, action: IStoreActions): NotificationStore {
    return produce(state, (draftState) => {
        switch (action.type) {
            case getType(notificationClear):
            case getType(notificationDismiss):
                delete draftState.active[action.payload];
                break;
            case getType(notificationShow):
                if (draftState.permanent.length === maxNotifications) {
                    draftState.permanent.shift();
                }
                draftState.permanent.push(action.payload);
                draftState.active[action.payload.id] = action.payload;
                break;
            default:
                break;
        }
    });
}
