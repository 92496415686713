import React from 'react';
import { connect } from "react-redux";
import { ThunkDispatch } from "../state/store-types";
import { CallbackComponent } from "redux-oidc";
import userManager from "../utils/userManager";
import { push } from "connected-react-router";
import { REDIRECT_URI } from '../utils/constants';

interface DispatchProps {
    redirectBack: () => void;
}

type Props = DispatchProps;

function Callback(props: Props) {
    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={props.redirectBack}
            errorCallback={(error) => console.log(error)}
        >
            <div>Redirecting..</div>
        </CallbackComponent>
    );
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        redirectBack: () => {
            // let's see if we have stored redirect uri to localstorage
            const redirectPath = localStorage.getItem(REDIRECT_URI);
            dispatch(push(redirectPath!));
        }
    };
}

export default connect(null, mapDispatchToProps)(Callback);
