import Konva from 'konva';
import memoize from 'micro-memoize';
import { createSelector } from 'reselect';
import { Viewpoint } from '../diagram-viewport';
import { Extent } from '../proteus/Extent';
import { Position } from '../proteus/Position';
import { DEFAULT_NOTIFICATION_CALLBACK } from '../proteus/ProteusRenderer';
import { getBoundingBox, getExtent, isConnectionType, readShapeExtents, readShapeGraphics } from "../proteus/ProteusXML";
import { viewpointFromExtent } from "../proteus/Viewpoint";
import { getDocumentByPossibleDiagramId } from '../state/diagrams';
import { extentFromSelectionRectangle, SelectionRectangle } from "./SelectionRectangle";

export const getElementCache_: (document?: Document) => ElementCache =
    memoize(document => new ElementCache(document), { maxSize: 4 });

export const getElementCache = (document?: Document): ElementCache => {
    const elementCache = getElementCache_(document);
    elementCache.initialize(DEFAULT_NOTIFICATION_CALLBACK);
    return elementCache;
}

export const elementCacheSelector =
    createSelector(
        getDocumentByPossibleDiagramId,
        getElementCache
    );

function classifyElement(element: ChildNode) {
    switch (element.nodeName) {
        case 'PropertyBreak':
        case 'InstrumentComponent':
        case 'PipingComponent':
        case 'ProcessInstrument':
        case 'PipeConnectorSymbol':
        case 'SignalConnectorSymbol':
        case 'ActuatingSystemComponent':
        case 'ProcessSignalGeneratingSystemComponent':
        case 'InformationFlow':
        case 'Nozzle':
        case 'SignalLine':
        case 'PipeFlowArrow':
        case 'Label':
        case 'PipeOffPageConnector':
        case 'InformationFlowOffPageConnector':
        case 'InstrumentationLoopFunction':
        case 'ProcessSignalGeneratingFunction':
            return 'selectable';
        case 'CenterLine':
            if (element.parentElement!.nodeName === 'SignalLine' || element.parentElement!.nodeName === 'InformationFlow')
                return 'dontFollow';
            else
                return 'selectable';
        case 'Equipment':
        case 'ProcessInstrumentationFunction':
            return 'selectableWithChildren'
        case 'PipingNetworkSystem':
        case 'PipingNetworkSegment':
            return 'skipExtent';
        case 'ShapeCatalogue':
        case 'Drawing':
        case 'GenericAttributes':
        case '#text':
        case 'Connection':
        case 'PlantInformation':
        case 'Extent':
        case 'PersistentID':
        case 'Position':
        case 'Presentation':
        case 'NominalDiameter':
        case 'MaximumDesignTemperature':
        case 'MinimumDesignTemperature':
        case 'MaximumDesignPressure':
        case 'PolyLine':
        case 'Line':
        case 'Text':
        case 'TrimmedCurve':
        case 'Ellipse':
        case 'Circle':
            return 'dontFollow';
        default:
            /*        case '#document':
                    case 'PlantModel':
                    case 'PipingNetworkSystem':
                    case 'PipingNetworkSegment':*/
            return 'unknown';
    }
}

export class ElementCache {
    colorMode?: number;
    document?: Document;
    elements?: HTMLElement[];
    extents?: [Extent, HTMLElement, boolean][];
    extentMap?: { [id: string]: [Extent, HTMLElement, boolean] };
    skipExtent: HTMLElement[] = [];
    shapeGraphics?: Map<string, Konva.Group>;
    shapeExtents?: Map<string, Extent>;

    constructor(document?: Document) {
        this.document = document;
    }

    initialize = (notificationCallback: (message: string) => void) => {
        if (this.shapeGraphics === undefined) {
            this.shapeGraphics = this.document ? readShapeGraphics(this.document, this.colorMode!, notificationCallback) : new Map([]);
            this.shapeExtents = this.document ? readShapeExtents(this.document) : new Map([]);
        }
    }

    private findElements = (element: ChildNode) => {
        switch (classifyElement(element)) {
            case 'selectable':
                this.elements!.push(element as HTMLElement);
                return;
            case 'selectableWithChildren':
                this.elements!.push(element as HTMLElement);
                element.childNodes.forEach(this.findElements);
                return;
            case 'skipExtent':
                this.elements!.push(element as HTMLElement);
                this.skipExtent.push(element as HTMLElement);
                element.childNodes.forEach(this.findElements);
                return;
            case 'unknown':
                element.childNodes.forEach(this.findElements);
                return;
            case 'dontFollow':
                return;
        }
    }

    getElements = () => {
        if (!this.elements) {
            this.elements = [];
            if (this.document)
                this.findElements(this.document.getRootNode() as Element);
        }
        return this.elements;
    }

    getElementsByIds = (ids: string[]) => {
        const extentMap = this.getExtentMap();
        return ids
            .map(id => extentMap[id])
            .filter(entry => !!entry)
            .map(entry => entry[1]);
    }

    getExtents = (): [Extent, HTMLElement, boolean][] => {
        if (!this.extents) {
            this.extents = [];
            for (const element of this.getElements()) {
                if (this.skipExtent.includes(element)) {
                    this.extents.push([Extent.empty(), element, false]);
                    continue;
                }
                const extent = getExtent(element);
                if (extent)
                    this.extents.push([extent, element, false]);
                else {
                    const boundingBox = getBoundingBox(element, this.shapeExtents, false);
                    if (boundingBox)
                        this.extents.push([boundingBox, element, true]);
                }
            }
        }
        return this.extents;
    }

    getExtentMap = (): { [id: string]: [Extent, HTMLElement, boolean] } => {
        if (!this.extentMap) {
            this.extentMap = {};
            for (const tuple of this.getExtents()) {
                const id = tuple[1].getAttribute("ID");
                if (id)
                    this.extentMap[id] = tuple;
            }
        }
        return this.extentMap;
    }

    getExactExtent = (element: HTMLElement): Extent | undefined => {
        const id = element.getAttribute("ID");
        if (!id)
            return getBoundingBox(element, this.shapeExtents, false);

        const tuple = this.getExtentMap()[id];
        if (tuple) {
            if (tuple[2])
                return tuple[0];
            const extent = getBoundingBox(tuple[1], this.shapeExtents, false);
            if (extent)
                tuple[0] = extent;
            tuple[2] = true;
            return extent;
        }
        else
            return getBoundingBox(element, this.shapeExtents, false);
    }

    getCenter = (element: HTMLElement): Position | undefined => {
        const extent = this.getExactExtent(element);
        return extent && { x: 0.5 * (extent.minX + extent.maxX), y: 0.5 * (extent.minY + extent.maxY) };
    }

    getCenterById = (elementId: string): Position | undefined => {
        const extent = this.getExactExtentById(elementId);
        return extent && { x: 0.5 * (extent.minX + extent.maxX), y: 0.5 * (extent.minY + extent.maxY) };
    }

    getExactExtentById = (id: string): Extent | undefined => {
        const tuple = this.getExtentMap()[id];
        if (tuple) {
            if (tuple[2])
                return tuple[0];
            const extent = getBoundingBox(tuple[1], this.shapeExtents, false);
            if (extent)
                tuple[0] = extent;
            tuple[2] = true;
            return extent;
        }
    }

    getElement = (id: string): HTMLElement | undefined => {
        const tuple = this.getExtentMap()[id];
        if (tuple)
            return tuple[1];
        else
            return;
    }

    public pick = (position_: Position) => {
        const position = { x: position_.x, y: -position_.y };
        let bestArea = Infinity;
        let bestElement;
        for (const tuple of this.getExtents()) {
            const element = tuple[1];
            if (isConnectionType(element.localName))
                continue;
            if (tuple[0].isInside(position)) {
                if (!tuple[2]) {
                    tuple[2] = true;
                    const exactExtent = getBoundingBox(element, this.shapeExtents, false);
                    if (exactExtent) {
                        tuple[0] = exactExtent;
                        if (!exactExtent.isInside(position)) {
                            continue;
                        }
                    }
                }
                const area = tuple[0].area();
                if (area < bestArea) {
                    bestElement = element;
                    bestArea = area;
                }
            }
        }
        return bestElement;
    }

    public pickArea = (box: SelectionRectangle) => {
        const elements: HTMLElement[] = [];
        const area = extentFromSelectionRectangle({
            begin: { x: box.begin.x, y: -box.begin.y },
            end: { x: box.end.x, y: -box.end.y }
        });
        for (const tuple of this.getExtents()) {
            const [extent, element, exact] = tuple;
            if (exact) {
                if (area.doesInclude(extent))
                    elements.push(element);
            }
            else {
                if (area.doesIntersect(extent)) {
                    tuple[2] = true;
                    const exactExtent = getBoundingBox(element, this.shapeExtents, false);
                    if (exactExtent) {
                        tuple[0] = exactExtent;
                        if (area.doesInclude(exactExtent))
                            elements.push(element);
                    }
                }
            }
        }
        return elements;
    }

    public viewpointFromIds(ids: string[]): Viewpoint | undefined {
        const extent = this.extentFromIds(ids);
        if (extent === undefined)
            return undefined;

        const center = extent.center();
        center.y = -center.y;

        let scale = Math.hypot(extent.maxX - extent.minX, extent.maxY - extent.minY);
        if (scale < 1e-6)
            return undefined;
        else
            scale = 100 / scale;

        return { centerX: center.x, centerY: center.y, scale };
    }

    public extentFromIds(ids: string[]): Extent | undefined {
        return Extent.combine(ids.map(this.getExactExtentById));
    }

}

export function calculateViewpointFromSelection(size: { width: number, height: number }, elementCache: ElementCache, elements: HTMLElement[]): Viewpoint | undefined {
    const extent = Extent.empty();
    for (const element of elements) {
        const elementExtent = elementCache.getExactExtent(element);
        if (elementExtent)
            extent.expandToIncludeExtent(elementExtent);
    }
    return viewpointFromExtent(0.5, size, extent);
}
