import React from 'react';
import { Route, Switch } from 'react-router';
import Callback from './Auth/Callback';
import ProtectedApplication from './ProtectedApplication';

export function Application() {
  return <Switch>
      <Route exact path="/callback" component={Callback} />
      <Route path="/" component={ProtectedApplication} />
    </Switch>;
}
