import { Viewpoint } from "../diagram-viewport";
import { Extent } from "./Extent";
import { getExtent } from "./ProteusXML";

export function viewpointFromExtent(scalingFactor: number, size: { width: number, height: number }, extent?: Extent): Viewpoint | undefined {
    if (extent === undefined)
        return undefined;

    const { minX, minY, maxX, maxY } = extent;
    if (!isFinite(minX))
        return undefined;

    const viewpoint = {
        centerX: 0.5 * (minX + maxX),
        centerY: -0.5 * (minY + maxY),
        scale: Math.min(
            size.width / (maxX - minX),
            size.height / (maxY - minY)
        ) * scalingFactor
    };
    return viewpoint;
}

export function calculateViewpointFromDocument(size: { width: number, height: number }, document: Document): Viewpoint | undefined {
    const root = document.getRootNode() as Element;
    const extent = getExtent(root.firstChild as Element);
    return viewpointFromExtent(0.8, size, extent);
}
