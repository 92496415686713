import React from 'react';
import { StoreState } from "../state";
import { OidcState } from "../state/auth";
import { connect } from "react-redux";
import { useEffect } from "react";
import userManager from "../utils/userManager";
import { REDIRECT_URI } from '../utils/constants';

interface StatePropsFromStore {
    oidcState: OidcState;
}

type Props = StatePropsFromStore;

function Login(props: Props) {
    useEffect(() => {
        // store the current path to localstorage for later use
        if (props.oidcState.userExpired) {
            localStorage.setItem(REDIRECT_URI, window.location.pathname)
            userManager.signinRedirect();
        }
    }, [props.oidcState.userExpired])
    return (
        <div>Redirecting...</div>
    )
}

function mapStateToProps(state: StoreState): StatePropsFromStore {
    const { oidcState } = state;
    return {
        oidcState,
    };
}

export default connect(mapStateToProps)(Login);
