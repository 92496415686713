import jsLogger from 'js-logger';

const level = process.env.NODE_ENV === 'production' ? jsLogger.INFO : jsLogger.DEBUG;

jsLogger.useDefaults({
    defaultLevel: level,
    formatter: (messages: string[], context: any) => {
        const date = new Date();
        const currentDate = date.toLocaleDateString();
        const currentTime = date.toLocaleTimeString();
        const prefix = context.level.name + ' [' + currentDate + ' ' + currentTime + ']:';
        messages.unshift(prefix);
    }
});

const Logger = jsLogger.get('mb4c');

export const isDebug = () => {
    return level === jsLogger.DEBUG;
};

export default Logger;
