import { createAction, getType } from "typesafe-actions";
import { IStoreActions, StoreState } from ".";
import { RelatedEntitiesRequest, RelatedEntitiesResponse } from "../proto/control_pb";
import { ControlProject } from "../proto/control_pb_service";
import { GrpcActionPayload, grpcRequest } from "./grpc";
import { stdOnError } from "./grpcutils";
import { ThunkDispatch, ThunkResult } from "./store-types";

// Actions

export const updateResults = createAction('relatedEntities/updateResults', (resolve) => {
    return (relationId: string, sources: string[], diagramId: string, targets: string[]) => resolve({ relationId, sources, diagramId, targets });
});

export const relatedEntitiesActions = {
    updateResults
};

// State

export interface RelatedEntitiesState {
    relationId: string;
    sources: string[];
    diagramId: string;
    targets: string[];
}

const initialState: RelatedEntitiesState = {
    relationId: "",
    diagramId: "",
    sources: [],
    targets: []
}

// Reducer
export function relatedEntitiesReducer(state = initialState, action: IStoreActions): RelatedEntitiesState {
    if (action.type === getType(updateResults)) {
        return action.payload;
    }
    else
        return state;
}

// Derived actions

export const refreshRelatedEntities = (relationId: string, sources: string[], diagramId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        const request = new RelatedEntitiesRequest();
        request.setProjectid(projectId);
        request.setRelationid(relationId);
        request.setSourcesList(sources);
        request.setDiagramid(diagramId);

        const grpcAction: GrpcActionPayload<RelatedEntitiesRequest, RelatedEntitiesResponse> = {
            methodDescriptor: ControlProject.RelatedEntities,
            onError: (code, msg) => stdOnError(dispatch, 'Finding related entities')(code, msg),
            onMessage: msg => dispatch(updateResults(relationId, sources, diagramId, msg.getTargetsList())),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};
