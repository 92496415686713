// package: 
// file: control.proto

var control_pb = require("./control_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var controlStatusEvents_pb = require("./controlStatusEvents_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ControlProject = (function () {
  function ControlProject() {}
  ControlProject.serviceName = "ControlProject";
  return ControlProject;
}());

ControlProject.ProjectStatus = {
  methodName: "ProjectStatus",
  service: ControlProject,
  requestStream: false,
  responseStream: true,
  requestType: control_pb.ProjectStatusRequest,
  responseType: controlStatusEvents_pb.ProjectStatusEvent
};

ControlProject.CreateObject = {
  methodName: "CreateObject",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.CreateObjectRequest,
  responseType: control_pb.ObjectId
};

ControlProject.UpdateObject = {
  methodName: "UpdateObject",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.UpdateObjectRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.RemoveObject = {
  methodName: "RemoveObject",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.RemoveObjectRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.DuplicatePattern = {
  methodName: "DuplicatePattern",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.DuplicatePatternRequest,
  responseType: control_pb.DuplicatePatternResponse
};

ControlProject.RelatedEntities = {
  methodName: "RelatedEntities",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.RelatedEntitiesRequest,
  responseType: control_pb.RelatedEntitiesResponse
};

ControlProject.FindPatterns = {
  methodName: "FindPatterns",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.FindPatternsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.CancelUpdate = {
  methodName: "CancelUpdate",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.CancelUpdateRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.ConfirmInstance = {
  methodName: "ConfirmInstance",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.ConfirmInstanceRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.ConfirmAll = {
  methodName: "ConfirmAll",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.ConfirmAllRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.RemoveInstance = {
  methodName: "RemoveInstance",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.RemoveInstanceRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.CreateManualInstance = {
  methodName: "CreateManualInstance",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.CreateManualInstanceRequest,
  responseType: control_pb.ObjectId
};

ControlProject.AssignNode = {
  methodName: "AssignNode",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.AssignNodeRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.DetachNode = {
  methodName: "DetachNode",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.DetachNodeRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.RemoveInstancesWithMissingElements = {
  methodName: "RemoveInstancesWithMissingElements",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.RemoveInstancesWithMissingElementsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.RemoveAllInstances = {
  methodName: "RemoveAllInstances",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.RemoveAllInstancesRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.MapInstanceNodes = {
  methodName: "MapInstanceNodes",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.MapInstanceNodesRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.UpdateTypeExamples = {
  methodName: "UpdateTypeExamples",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.UpdateTypeExamplesRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.CalculateTypeSuggestions = {
  methodName: "CalculateTypeSuggestions",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.CalculateTypeSuggestionsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.CalculatePatternSuggestions = {
  methodName: "CalculatePatternSuggestions",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.CalculatePatternSuggestionsRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ControlProject.SearchElements = {
  methodName: "SearchElements",
  service: ControlProject,
  requestStream: false,
  responseStream: true,
  requestType: control_pb.SearchElementsRequest,
  responseType: control_pb.SearchElementsResult
};

ControlProject.FindCrossPageConnection = {
  methodName: "FindCrossPageConnection",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.FindCrossPageConnectionRequest,
  responseType: control_pb.FindCrossPageConnectionResponse
};

ControlProject.GetInstrumentIndexSize = {
  methodName: "GetInstrumentIndexSize",
  service: ControlProject,
  requestStream: false,
  responseStream: true,
  requestType: control_pb.GetTableSizeRequest,
  responseType: control_pb.GetTableSizeResponse
};

ControlProject.GetInstrumentIndexRange = {
  methodName: "GetInstrumentIndexRange",
  service: ControlProject,
  requestStream: false,
  responseStream: true,
  requestType: control_pb.GetTableRangeRequest,
  responseType: control_pb.TableRow
};

ControlProject.GetInstrumentIndexInfo = {
  methodName: "GetInstrumentIndexInfo",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.GetTableInfoRequest,
  responseType: control_pb.GetTableInfoResponse
};

ControlProject.GetTagListSize = {
  methodName: "GetTagListSize",
  service: ControlProject,
  requestStream: false,
  responseStream: true,
  requestType: control_pb.GetTableSizeRequest,
  responseType: control_pb.GetTableSizeResponse
};

ControlProject.GetTagListRange = {
  methodName: "GetTagListRange",
  service: ControlProject,
  requestStream: false,
  responseStream: true,
  requestType: control_pb.GetTableRangeRequest,
  responseType: control_pb.TableRow
};

ControlProject.Validate = {
  methodName: "Validate",
  service: ControlProject,
  requestStream: false,
  responseStream: false,
  requestType: control_pb.ValidateRequest,
  responseType: control_pb.ValidateResponse
};

exports.ControlProject = ControlProject;

function ControlProjectClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ControlProjectClient.prototype.projectStatus = function projectStatus(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ControlProject.ProjectStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.createObject = function createObject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.CreateObject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.updateObject = function updateObject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.UpdateObject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.removeObject = function removeObject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.RemoveObject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.duplicatePattern = function duplicatePattern(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.DuplicatePattern, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.relatedEntities = function relatedEntities(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.RelatedEntities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.findPatterns = function findPatterns(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.FindPatterns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.cancelUpdate = function cancelUpdate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.CancelUpdate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.confirmInstance = function confirmInstance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.ConfirmInstance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.confirmAll = function confirmAll(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.ConfirmAll, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.removeInstance = function removeInstance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.RemoveInstance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.createManualInstance = function createManualInstance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.CreateManualInstance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.assignNode = function assignNode(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.AssignNode, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.detachNode = function detachNode(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.DetachNode, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.removeInstancesWithMissingElements = function removeInstancesWithMissingElements(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.RemoveInstancesWithMissingElements, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.removeAllInstances = function removeAllInstances(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.RemoveAllInstances, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.mapInstanceNodes = function mapInstanceNodes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.MapInstanceNodes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.updateTypeExamples = function updateTypeExamples(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.UpdateTypeExamples, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.calculateTypeSuggestions = function calculateTypeSuggestions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.CalculateTypeSuggestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.calculatePatternSuggestions = function calculatePatternSuggestions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.CalculatePatternSuggestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.searchElements = function searchElements(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ControlProject.SearchElements, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.findCrossPageConnection = function findCrossPageConnection(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.FindCrossPageConnection, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.getInstrumentIndexSize = function getInstrumentIndexSize(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ControlProject.GetInstrumentIndexSize, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.getInstrumentIndexRange = function getInstrumentIndexRange(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ControlProject.GetInstrumentIndexRange, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.getInstrumentIndexInfo = function getInstrumentIndexInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.GetInstrumentIndexInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.getTagListSize = function getTagListSize(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ControlProject.GetTagListSize, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.getTagListRange = function getTagListRange(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ControlProject.GetTagListRange, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ControlProjectClient.prototype.validate = function validate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ControlProject.Validate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ControlProjectClient = ControlProjectClient;

