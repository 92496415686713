import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// @ts-ignore
import 'fomantic-ui-css/semantic.min.css';
import { Application } from './Application';
import './index.css';
import configureStore, { history } from './state';
import { OidcProvider } from 'redux-oidc';
import userManager from './utils/userManager';
// @ts-ignore
import en from 'javascript-time-ago/locale/en';
import JavascriptTimeAgo from 'javascript-time-ago';

JavascriptTimeAgo.locale(en);

const store = configureStore();

ReactDOM.render(
    (
        <Provider store={store}>
            <OidcProvider store={store} userManager={userManager}>
                <ConnectedRouter history={history}>
                    <Application />
                </ConnectedRouter>
            </OidcProvider>
        </Provider>
    ),
    document.getElementById('root') as HTMLElement
);
