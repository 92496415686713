import { ProjectCollection } from "./project";

export interface ObjectId {
    "$oid": string
}

export function toObjectId(id: string): ObjectId {
    return { "$oid": id };
}

export function toPossibleObjectId(id?: string): ObjectId | undefined {
    return id === undefined ? undefined : toObjectId(id);
}

export function fromObjectId(objectId: ObjectId): string {
    return objectId["$oid"];
}

export function fromPossibleObjectId(objectId?: ObjectId): string | undefined {
    return objectId ? fromObjectId(objectId) : undefined;
}

export interface Item {
    name: string;
}

export interface Diagram extends Item {
    content: ObjectId;
    thumbnailContent: ObjectId;
}

/// Types

export interface PatternType extends Item {
    id?: string;
    shouldBeMatched: boolean;
    basedOnExamples: boolean;
    superType?: ObjectId;
    properties: PropertyConstraint[];
}

export interface PropertyConstraint {
    name: string;
    pattern: string;
}

export function fixType(object: any) {
    if (object.properties === undefined)
        object.properties = [];
    if (object.shouldBeMatched === undefined)
        object.shouldBeMatched = false;
    if (object.basedOnExamples === undefined)
        object.basedOnExamples = false;
    return object;
}

/// Relations

export interface PatternRelation extends Item {
    id?: string;
    pattern: string;
}

export function fixRelation(object: any) {
    if (typeof object.pattern === 'undefined')
        object.pattern = "";
    return object;
}

/// Patterns

export const DEFAULT_PATTERN_COLOR = '#008000';

export interface Pattern extends Item {
    id?: string;
    enabled: boolean;
    color?: string;
    confirmAutomatically: boolean;
    priority: string;
    nodes: PatternNode[];
    edges: PatternEdge[];
    activeNodeProperties?: string[];
    target: PatternTarget;
    thumbnailContent?: ObjectId;
    thumbnailHeight: number;
}

export interface PatternNode {
    name: string;
    type?: ObjectId;
    tagNamePattern?: string;
    shareable: boolean;
    nodePropertyPatterns?: {[property: string]: string};
}

export interface PatternEdge {
    from: string;
    to: string;
    relation?: ObjectId;
}

export function encodeFieldName(name: string): string {
    return name.replace("\\", "\\\\").replace("$", "\\u0024").replace(".", "\\u002e");
}

export function decodeFieldName(name: string): string {
    return name.replace("\\u002e", ".").replace("\\u0024", "$").replace("\\\\", "\\");
}

export type TargetRow = { [column: string]: string };

export interface PatternTarget {
    columns: string[];
    rows: TargetRow[];
    preparationScript: string;
}

export const defaultTargetColumns = [
    "Pid_Sht",
    "Pid_Tag",
    "IO_Type",
    "System",
    "CLM_Tag_Name",
    "HMItag"
];

export function fixPattern(object: any) {
    if (typeof object.priority === 'undefined')
        object.priority = "0";
    if (typeof object.nodes === 'undefined')
        object.nodes = [];
    else {
        for (const node of object.nodes)
            if (typeof node.shareable === 'undefined')
                node.shareable = false;
    }
    if (typeof object.edges === 'undefined')
        object.edges = [];
    if (typeof object.target === 'undefined')
        object.target = {
            columns: defaultTargetColumns,
            rows: [],
            preparationScript: ""
        };
    else {
        if (typeof object.target.preparationScript === 'undefined')
            object.target.preparationScript = "";
    }
    return object;
}

export interface AvailableAttributes {
    value: string[];
}

export interface TargetColumns {
    outputColumns: string[];
    auxiliaryColumns: string[];
    script: string;
    sharedScript?: string;
}

export type ColumnScripts = {
    [columnName: string]: string;
};

export function fixTargetColumns(object: any) {
    if (object.outputColumns === undefined)
        object.outputColumns = [];
    if (object.auxiliaryColumns === undefined)
        object.auxiliaryColumns = [];
    if (object.script === undefined)
        object.script = "";
    return object;
}

/// PatternTypeSearch

export interface PatternTypeSearch {
    result: string[];
}

/// PatternSearch

export interface PatternInstance {
    id: string;
    pattern: ObjectId;
    confirmed: boolean;
    elements: string[];
    tags?: string[];
    containingDiagrams: string[];
}

export interface PlantItem {
    tagName?: string;
    diagram?: string;
}

export type PlantItemIndex = { [id: string]: PlantItem };
export const emptyPlantItemIndex: PlantItemIndex = {};

type RawPlantItemIndex = { items: { DIAGRAM: ObjectId, PROTEUS_ID: string, TagName: string }[] };

export function fixPlantItemIndex(object: RawPlantItemIndex): PlantItemIndex {
    const result: PlantItemIndex = {}
    for (const item of object.items) {
        const id = item.PROTEUS_ID;
        if (typeof id !== 'undefined')
            result[id] = {
                tagName: item.TagName,
                diagram: fromObjectId(item.DIAGRAM)
            };
    }
    return result;
}

export function searchPlantItemIndices(elementId : string, indices: ProjectCollection<PlantItemIndex>) : PlantItem | undefined {
    const it = indices.values();
    let result = it.next();
    while (!result.done) {
        const index = result.value;
        const item = index[elementId];
        if (item)
            return item;
        result = it.next();
    }
    return undefined;
}

/// Conflict

export interface Conflict {
    instances: TypeSearchRef[];
}

export interface TypeSearchRef {
    patternId: string;
    instanceId: string;
}

export function fixConflict(object: any): Conflict {
    for (const ref of object.instances) {
        ref.patternId = fromObjectId(ref.patternId);
        ref.instanceId = fromObjectId(ref.instanceId);
    }
    return object as Conflict;
}

// Problems

export interface Problems {
    id: string;
    problems: string[];
}

/// TagList

export type Row = { [column: string]: string };

export interface TagListEntry {
    primaryDiagram: string;
    primaryPosition: number;
    rows: Row[];
}

/// TypeExamples

export interface TypeExamples {
    positive: string[];
    negative: string[];
}

export const DEFAULT_TYPE_EXAMPLES: TypeExamples = {
    positive: [],
    negative: [],
};

/// TypeSuggestions

export interface TypeSuggestion {
    type: ObjectId;
    suggestion: PropertyConstraint;
    matchCountAlone: number;
    matchCountWithExisting: number;
}

/// PatternSuggestions

export interface PatternSuggestion {
    id: string;
    pattern: ObjectId;
    kind: "nodeType" | "tagNamePattern" | "propertyPattern" | "edge";
    parameters: { [key: string]: any };
}

/// InstrumentIndexFile

export interface InstrumentIndexFile {
    id: string;
    name: string;
}

export interface InstrumentIndexColumns {
    columnNames: string[];
}

export interface Status {
    progress: number;
    taskName: string;
    errorDescription?: string;
}

export interface ProjectProperty {
    id: string;
    key: string;
    value: any;
}
