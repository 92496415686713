import { Set } from "immutable";
import { createAction, getType } from "typesafe-actions";
import { IStoreActions } from ".";

// Actions

export const setTypeExpanded = createAction('types/setTypeExpanded', (resolve) => {
    return (typeId: string, expanded: boolean) => resolve({ typeId, expanded });
});

export const typesActions = {
    setTypeExpanded
};

// State

export interface TypesState {
    expanded: Set<string>;
}

const initialState: TypesState = {
    expanded: Set(),
}

// Reducer
export function typesReducer(state = initialState, action: IStoreActions): TypesState {
    if (action.type === getType(setTypeExpanded)) {
        const { typeId, expanded } = action.payload;
        return {
            ...state,
            expanded: expanded
                ? state.expanded.add(typeId)
                : state.expanded.remove(typeId)
        };
    }
    else
        return state;
}