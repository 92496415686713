import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { ViewportContext, ViewportState, Extent } from './Viewport';

export type SVGLoadingResult = { status: 'loading' | 'error', message: string }
    | { status: 'loaded', svg: SVGElement, extent: Extent };
export const LOADING = (message: string): SVGLoadingResult => ({ status: 'loading', message });
export const LOADING_DIAGRAM = LOADING("Loading diagram");
export const RENDERING_DIAGRAM = LOADING("Rendering diagram");
export const LOADED = (svg: SVGElement, extent: Extent): SVGLoadingResult => ({ status: 'loaded', svg, extent });
export const ERROR = (message: string): SVGLoadingResult => ({ status: 'error', message });

export function loadSVG(url: string, requestInit?: RequestInit) {
    const [state, setState] = useState(LOADING_DIAGRAM);
    useEffect(() => {
        const handler = async () => {
            try {
                const response = await fetch(url, requestInit);
                const text = await response.text();

                const parser = new DOMParser();
                const doc = parser.parseFromString(text, "image/svg+xml");
                const svg = doc.getElementsByTagName("svg").item(0) as any as SVGSVGElement;
                const { x, y, width, height } = svg.viewBox.baseVal;
                setState(LOADED(svg, {
                    minX: x,
                    minY: y,
                    maxX: x + width,
                    maxY: y + height
                }));
            } catch (error) {
                setState(ERROR(error));
            }
        };
        handler();
    }, [url]);
    return state;
}

interface Props {
    svg?: SVGElement;
}

const style: CSSProperties = {
    position: 'absolute',
    left: 0,
    right: 0
};

function setSVGViewport(svg: SVGElement, viewportState: ViewportState) {
    if (!svg)
        return;
    const { centerX, centerY, scale, width, height } = viewportState;
    const w = width / scale;
    const h = height / scale;
    svg.setAttribute("viewBox",
        `${centerX - 0.5 * w} ${centerY - 0.5 * h} ${w} ${h}`);
    svg.setAttribute("width", `${width}px`);
    svg.setAttribute("height", `${height}px`);
}

export function SVGLayer(props: { svg: SVGLoadingResult }) {
    const { svg } = props;
    if (svg.status === 'loaded')
        return <BaseSVGLayer svg={svg.svg} />;
    else
        return <MessageBox error={svg.status === 'error'} message={svg.message} />;
}

function MessageBox(props: { error: boolean, message: string }) {
    const { error, message } = props;
    return <div style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: 'center', color: error ? 'red' : 'black' }}>
        {message}
    </div>;
}

export const BaseSVGLayer = React.memo((props: { svg: SVGElement }) => {
    const { svg } = props;
    const rootRef = useRef(null as HTMLDivElement | null);
    const viewportState = useContext(ViewportContext);
    useEffect(() => {
        setSVGViewport(svg, viewportState);
    }, [svg, viewportState]);
    useEffect(() => {
        const root = rootRef.current!;
        root.append(svg);
        return () => { root.removeChild(svg); }
    }, [svg]);
    return <div ref={rootRef} style={style} />
});
