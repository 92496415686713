import Konva from "konva";

export type KonvaLineStyle =
    Pick<Konva.ShapeConfig,
        'stroke'
        | 'strokeWidth'
        | 'dash'
    >;

export function scaleLineStyle(scale: number, style: KonvaLineStyle): KonvaLineStyle {
    const s = Object.assign({}, style);
    if (s.strokeWidth !== undefined)
        s.strokeWidth /= scale;
    if (s.dash !== undefined)
        s.dash = s.dash.map(v => v / scale);
    return s;
}

export type KonvaTextStyle =
    Pick<Konva.TextConfig,
        'fontFamily'
        | 'fontSize'
        | 'fontStyle'
        | 'fillColor'
    >;
