import React, { CSSProperties, useContext, useRef, RefObject } from 'react';
import { Stage, KonvaNodeEvents } from 'react-konva';
import { ScaleContext, ViewportContext, CustomMouseEvent } from './Viewport';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';

interface Props {
    disableEvents?: boolean;
}

const style: CSSProperties = {
    position: 'absolute',
    left: 0,
    right: 0,
};

function getRelativeMousePosition(
    width: number,
    height: number,
    event: KonvaEventObject<MouseEvent>,
): [number, number] {
    const { clientX, clientY } = event.evt;
    return [clientX - 0.5 * width, clientY - 0.5 * height];
}

export const KonvaLayerGroup = React.memo((props: React.PropsWithChildren<Props>) => {
    const { children } = props;
    const viewportState = useContext(ViewportContext);
    const { width, height, scale, centerX, centerY, internalEventHandler } = viewportState;
    const convertEvent = (event: Konva.KonvaEventObject<MouseEvent>): CustomMouseEvent => {
        const { evt } = event;
        const { altKey, shiftKey, ctrlKey, button } = evt;
        return {
            screenPos: getRelativeMousePosition(width, height, event),
            altKey,
            shiftKey,
            ctrlKey,
            button,
            preventDefault: () => evt.preventDefault(),
            target: event.currentTarget,
        };
    };
    const eventHandler: KonvaNodeEvents = props.disableEvents
        ? {}
        : {
              onMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => {
                  internalEventHandler.onMouseDown(convertEvent(event));
              },
              onMouseMove: (event: Konva.KonvaEventObject<MouseEvent>) => {
                  internalEventHandler.onMouseMove(convertEvent(event));
              },
              onMouseUp: (event: Konva.KonvaEventObject<MouseEvent>) => {
                  internalEventHandler.onMouseUp(convertEvent(event));
              },
          };
    return (
        <Stage
            {...eventHandler}
            width={width}
            height={height}
            offsetX={centerX - (0.5 * width) / scale}
            offsetY={centerY - (0.5 * height) / scale}
            scaleX={scale}
            scaleY={scale}
            style={style}
        >
            <ViewportContext.Provider value={viewportState}>
                <ScaleContext.Provider value={scale}>{children}</ScaleContext.Provider>
            </ViewportContext.Provider>
        </Stage>
    );
});
