import { createAction, getType } from "typesafe-actions";
import { IStoreActions } from ".";
import { unsubscribed } from "./project";

export const revealTagName = createAction(
    "instrumentIndexView/revealTagName",
    (resolve) => {
        return (tagName?: string) => resolve(tagName);
    }
);

export const instrumentIndexViewActions = {
    revealTagName,
};

export interface InstrumentIndexViewState {
    tagNameToReveal?: string;
}

const initialState: InstrumentIndexViewState = {};

export function instrumentIndexViewReducer(
    state = initialState,
    action: IStoreActions
): InstrumentIndexViewState {
    if (action.type === getType(revealTagName)) {
        return {
            tagNameToReveal: action.payload,
        };
    } else if (action.type === getType(unsubscribed)) {
        return initialState;
    } else return state;
}
