import { createAction, getType } from "typesafe-actions";
import { IStoreActions } from ".";
import { unsubscribed } from "./project";

// Actions

export enum SelectionOp {
    Replace,
    Add,
    Toggle
}

export const setSelection = createAction('diagramSelection/changeSelection', (resolve) => {
    return (selection: string[], op: SelectionOp) => resolve({ selection, op });
});

export const diagramSelectionActions = {
    setSelection,
};

// State

export interface DiagramSelectionState {
    selection: string[];
}

const emptySelection: string[] = [];

const initialState: DiagramSelectionState = {
    selection: emptySelection
}

// Reducer
export function diagramSelectionReducer(state = initialState, action: IStoreActions): DiagramSelectionState {
    if (action.type === getType(setSelection)) {
        const { selection, op } = action.payload;
        if (op === SelectionOp.Replace || state.selection.length === 0)
            return { selection };

        const selectionSet: Set<string> = new Set(state.selection);
        for (const element of selection) {
            if (selectionSet.has(element)) {
                if (op === SelectionOp.Toggle)
                    selectionSet.delete(element);
            }
            else
                selectionSet.add(element);
        }
        return {
            selection: Array.from(selectionSet)
        }
    }
    else if (action.type === getType(unsubscribed)) {
        return initialState;
    }
    else
        return state;
}

