import React, { KeyboardEventHandler, MouseEvent, MouseEventHandler, useEffect, useMemo, useReducer, WheelEventHandler, useContext } from "react";
import ReactResizeDetector from "react-resize-detector";
import { ClickEvent, DiagramDragEventHandler, DiagramEventHandler, DiagramPosition, DragStartedEvent, KeyEvent, BoxSelectionEvent, EventModifiers } from "./Events";
// @ts-ignore
import normalizeWheel from 'normalize-wheel';

/**
 * How many pixels mouse must move before drag is considered as drag.
 */
const DRAG_MOVEMENT_TOLERANCE = 2;
const MOUSE_CLICK_AND_DRAG_BUTTON = 0;
const MOUSE_PAN_BUTTON = 1;
const MOUSE_PAN_BUTTON_MASK = 4;
const FIT_SCALE_MULTIPLIER = 0.9;

const MOUSE_INTERACTION_NO_INTERACTION = 0;
const MOUSE_INTERACTION_ONLY_CLICK = 1;
const MOUSE_INTERACTION_ONLY_DRAG = 2;
const MOUSE_INTERACTION_CLICK_AND_DRAG = 3;

export interface Extent {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
}

export interface Viewpoint {
    scale: number;
    centerX: number;
    centerY: number;
}

interface ViewportProps extends Pick<
    React.HTMLProps<any>,
    'className' | 'role' | 'style' | 'tabIndex' | 'title'
    >, DiagramEventHandler {
    zoomFactor: number;
    extent?: Extent;
    allowClick: boolean;
    allowDrag: boolean;
    initialViewpoint?: Viewpoint;
    onViewpointChanged?: (viewpoint: Viewpoint) => void;
}

export interface BoundingBox {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
}

export interface InternalViewportState {
    width: number;
    height: number;
    scale: number;
    centerX: number;
    centerY: number;
    autofit: boolean;
    autofitExtent?: Extent;
}

const defaultInternalViewportState: InternalViewportState = {
    width: 0,
    height: 0,
    scale: 1.0,
    centerX: 0,
    centerY: 0,
    autofit: true,
};

export interface ViewportState {
    width: number;
    height: number;
    scale: number;
    centerX: number;
    centerY: number;
    dispatch: (action: ViewportAction) => void;
    registerEventHandler: (eventHandlerProvider: () => DiagramEventHandler, deps: any[]) => void;
    internalEventHandler: InternalDiagramEventHandler;
}

export interface InternalDiagramEventHandler {
    onBoxSelection: (event: BoxSelectionEvent) => void;
    onMouseDown: CustomMouseEventHandler;
    onMouseMove: CustomMouseEventHandler;
    onMouseUp: CustomMouseEventHandler;
}

const defaultViewportState: ViewportState = {
    width: 0,
    height: 0,
    scale: 1.0,
    centerX: 0,
    centerY: 0,
    dispatch: () => { },
    registerEventHandler: () => { },
    internalEventHandler: {
        onBoxSelection: () => { },
        onMouseDown: () => { },
        onMouseMove: () => { },
        onMouseUp: () => { },
    },
};

export const ViewportContext = React.createContext(defaultViewportState);
export const ScaleContext = React.createContext(1.0);

export const useScale = () => useContext(ScaleContext);

export type ViewportAction =
    { type: 'resize', width: number, height: number } |
    { type: 'pan', screen: [number, number], diagram: [number, number] } |
    { type: 'zoom', anchor: [number, number], multiplier: number } |
    { type: 'fit', extent: Extent }
    ;

function fit(state: InternalViewportState, extent: Extent): InternalViewportState {
    const { width, height } = state;
    const { minX, minY, maxX, maxY } = extent;
    let scale;
    if (minX < maxX && width > 0) {
        if (minY < maxY && height > 0)
            scale = FIT_SCALE_MULTIPLIER * Math.min(width / (maxX - minX), height / (maxY - minY));
        else
            scale = FIT_SCALE_MULTIPLIER * width / (maxX - minX);
    }
    else {
        if (minY < maxY && height > 0)
            scale = FIT_SCALE_MULTIPLIER * height / (maxY - minY);
        else
            scale = 1;
    }
    return {
        ...state,
        scale,
        centerX: 0.5 * (minX + maxX),
        centerY: 0.5 * (minY + maxY)
    };
}

function viewportReducer(state: InternalViewportState, action: ViewportAction): InternalViewportState {
    switch (action.type) {
        case 'resize':
            state = {
                ...state,
                width: action.width,
                height: action.height
            };
            if (state.autofit && state.autofitExtent)
                state = fit(state, state.autofitExtent);
            return state;
        case 'pan':
            return {
                ...state,
                autofit: false,
                centerX: action.diagram[0] - action.screen[0] / state.scale,
                centerY: action.diagram[1] - action.screen[1] / state.scale
            };
        case 'zoom': {
            const s = state.scale * (1.0 - action.multiplier);
            return {
                ...state,
                autofit: false,
                scale: state.scale * action.multiplier,
                centerX: action.anchor[0] - (action.anchor[0] - state.centerX) / action.multiplier,
                centerY: action.anchor[1] - (action.anchor[1] - state.centerY) / action.multiplier
            };
        }
        case 'fit':
            state = { ...state, autofit: true, autofitExtent: action.extent };
            return fit(state, action.extent);
    }
    return state;
}

interface MouseActionContext {
    initScreenPos: DiagramPosition;
    initDiagramPos: DiagramPosition;
    initModifiers: EventModifiers;
    dragEventHandler?: DiagramDragEventHandler;
}

export interface CustomMouseEvent {
    screenPos: [number, number];
    button: number;
    altKey: boolean;
    ctrlKey: boolean;
    shiftKey: boolean;
    preventDefault: () => void;
    target?: any;
}
type CustomMouseEventHandler = (event: CustomMouseEvent) => void;

class EventHandlers {

    props: ViewportProps;
    state: InternalViewportState = defaultInternalViewportState;
    dispatch: (action: ViewportAction) => void = () => { };
    eventHandlers: DiagramEventHandler[] = [];
    interactionType = MOUSE_INTERACTION_CLICK_AND_DRAG;

    constructor(props: ViewportProps) {
        this.props = props;
    }

    handleClick = (event: ClickEvent): boolean => {
        for (const eventHandler of this.eventHandlers) {
            if (eventHandler.onClick !== undefined && eventHandler.onClick(event))
                return true;
        }
        return this.props.onClick !== undefined && this.props.onClick(event);
    }
    handleKey = (event: KeyEvent): boolean => {
        for (const eventHandler of this.eventHandlers) {
            if (eventHandler.onKey !== undefined && eventHandler.onKey(event))
                return true;
        }
        return this.props.onKey !== undefined && this.props.onKey(event);
    }
    handleDragStarted = (event: DragStartedEvent): boolean => {
        for (const eventHandler of this.eventHandlers) {
            if (eventHandler.onDragStarted !== undefined &&
                (this.mouseActionContext!.dragEventHandler = eventHandler.onDragStarted(event)) !== undefined)
                return true;
        }
        return this.props.onDragStarted !== undefined &&
            (this.mouseActionContext!.dragEventHandler = this.props.onDragStarted(event)) !== undefined
    }

    handleBoxSelection = (event: BoxSelectionEvent): boolean => {
        for (const eventHandler of this.eventHandlers) {
            if (eventHandler.onBoxSelection !== undefined && eventHandler.onBoxSelection(event))
                return true;
        }
        return this.props.onBoxSelection !== undefined && this.props.onBoxSelection(event);
    }

    // Defined when a mouse action (drag or click) is ongoing
    mouseActionContext?: MouseActionContext;

    // Defined when pan is ongoing
    initPanPos?: DiagramPosition;

    // Defined when the mouse is inside the viewport
    curScreenPosition?: DiagramPosition;

    screenPos = (event: MouseEvent<HTMLDivElement>): [number, number] => {
        const { width, height } = this.state;
        const x = event.nativeEvent.offsetX;
        const y = event.nativeEvent.offsetY;
        return [x - 0.5 * width, y - 0.5 * height];
    }

    diagramPos = (screenPos: [number, number]): [number, number] => {
        const { scale, centerX, centerY } = this.state;
        const [x, y] = screenPos;
        return [
            x / scale + centerX,
            y / scale + centerY
        ];
    }

    isDrag = () => {
        const [x1, y1] = this.mouseActionContext!.initScreenPos;
        const [x2, y2] = this.curScreenPosition!;
        return Math.abs(x1 - x2) >= DRAG_MOVEMENT_TOLERANCE || Math.abs(y1 - y2) >= DRAG_MOVEMENT_TOLERANCE;
    };

    onMouseDown: CustomMouseEventHandler = (event) => {
        const { screenPos } = event;
        const diagramPos = this.diagramPos(screenPos);

        if (event.button === MOUSE_CLICK_AND_DRAG_BUTTON) {
            const { altKey, ctrlKey, shiftKey, target } = event;
            if (this.interactionType === MOUSE_INTERACTION_ONLY_CLICK)
                this.handleClick({
                    position: diagramPos,
                    altKey, ctrlKey, shiftKey,
                    scale: this.state.scale,
                    target
                });
            else {
                this.mouseActionContext = {
                    initScreenPos: screenPos,
                    initDiagramPos: diagramPos,
                    initModifiers: { altKey, ctrlKey, shiftKey, target }
                };
                if (this.interactionType === MOUSE_INTERACTION_ONLY_DRAG)
                    this.handleDragStarted({
                        startPosition: diagramPos,
                        altKey, ctrlKey, shiftKey,
                        scale: this.state.scale,
                        target
                    });
            }
        }
        else if (event.button === MOUSE_PAN_BUTTON) {
            this.initPanPos = diagramPos;
            event.preventDefault();
        }
    }

    onMouseMove: CustomMouseEventHandler = (event) => {
        const { screenPos } = event;
        this.curScreenPosition = screenPos;
        if (this.initPanPos) {
            this.dispatch({
                type: 'pan',
                diagram: this.initPanPos,
                screen: screenPos
            });
        }

        if (this.mouseActionContext) {
            const { dragEventHandler } = this.mouseActionContext;
            const { altKey, ctrlKey, shiftKey, target } = event;

            const position = this.diagramPos(this.curScreenPosition);
            if (dragEventHandler) {
                if (dragEventHandler.onDragMoved)
                    dragEventHandler.onDragMoved({
                        position,
                        altKey, ctrlKey, shiftKey,
                        scale: this.state.scale,
                        target
                    });
            }
            else {
                if (this.isDrag()) {
                    const startPosition = this.mouseActionContext.initDiagramPos;
                    if (this.handleDragStarted({
                        ...this.mouseActionContext.initModifiers,
                        startPosition,
                        scale: this.state.scale
                    }))
                        if (this.mouseActionContext.dragEventHandler!.onDragMoved)
                            this.mouseActionContext.dragEventHandler!.onDragMoved({
                                position,
                                altKey,
                                ctrlKey,
                                shiftKey,
                                scale: this.state.scale,
                                target
                            });
                }
            }
        }
    }

    onMouseUp: CustomMouseEventHandler = (event) => {
        const { screenPos } = event;
        if (event.button === MOUSE_PAN_BUTTON) {
            this.initPanPos = undefined;
        }
        else if (event.button === MOUSE_CLICK_AND_DRAG_BUTTON) {
            const { altKey, ctrlKey, shiftKey, target } = event;

            if (this.mouseActionContext) {
                const { dragEventHandler } = this.mouseActionContext;

                if (dragEventHandler) {
                    if (dragEventHandler.onDragFinished)
                        dragEventHandler.onDragFinished({
                            endPosition: this.diagramPos(this.curScreenPosition!),
                            altKey, ctrlKey, shiftKey,
                            scale: this.state.scale,
                            target
                        });
                }
                else if (this.interactionType === MOUSE_INTERACTION_CLICK_AND_DRAG) {
                    this.handleClick({
                        position: this.mouseActionContext.initDiagramPos,
                        altKey, ctrlKey, shiftKey,
                        scale: this.state.scale,
                        target
                    });
                }
                this.mouseActionContext = undefined;
            }
        }
    }

    onMouseEnter: MouseEventHandler<HTMLDivElement> = (event) => {
        if ((event.buttons & MOUSE_PAN_BUTTON_MASK) !== 0)
            this.initPanPos = this.diagramPos(this.screenPos(event));
    };

    onMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
        this.curScreenPosition = undefined;
        this.initPanPos = undefined;
        if (this.mouseActionContext && this.mouseActionContext.dragEventHandler &&
            this.mouseActionContext.dragEventHandler.onDragCancelled)
            this.mouseActionContext.dragEventHandler.onDragCancelled();
        this.mouseActionContext = undefined;
    };

    onWheel: WheelEventHandler<HTMLDivElement> = (event) => {
        const normalized = normalizeWheel(event.nativeEvent);
        this.dispatch({
            type: 'zoom',
            anchor: this.diagramPos(this.screenPos(event)),
            multiplier: Math.pow(this.props.zoomFactor, -normalized.spinY)
        });
    }

    centerPos = (): DiagramPosition => [this.state.centerX, this.state.centerY];
    keyboardZoomFactor = () => this.props.zoomFactor;

    onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        const { key } = event;
        const position = this.curScreenPosition ? this.diagramPos(this.curScreenPosition) : undefined;
        if (this.handleKey({
            key,
            position,
            scale: this.state.scale
        })) {
            event.preventDefault();
            return;
        }
        switch (key) {
            case 'ArrowLeft':
                this.dispatch({
                    type: 'pan',
                    diagram: this.centerPos(),
                    screen: [40, 0]
                });
                break;
            case 'ArrowRight':
                this.dispatch({
                    type: 'pan',
                    diagram: this.centerPos(),
                    screen: [-40, 0]
                });
                break;
            case 'ArrowUp':
                this.dispatch({
                    type: 'pan',
                    diagram: this.centerPos(),
                    screen: [0, 40]
                });
                break;
            case 'ArrowDown':
                this.dispatch({
                    type: 'pan',
                    diagram: this.centerPos(),
                    screen: [0, -40]
                });
                break;
            case '+':
                this.dispatch({
                    type: 'zoom',
                    anchor: position || this.centerPos(),
                    multiplier: this.keyboardZoomFactor()
                });
                break;
            case '-':
                this.dispatch({
                    type: 'zoom',
                    anchor: position || this.centerPos(),
                    multiplier: 1.0 / this.keyboardZoomFactor()
                });
                break;
            case 'Escape':
                if (this.mouseActionContext) {
                    if (this.mouseActionContext.dragEventHandler &&
                        this.mouseActionContext.dragEventHandler.onDragCancelled)
                        this.mouseActionContext.dragEventHandler.onDragCancelled();
                    this.mouseActionContext = undefined;
                }
                break;
            case '1':
                if (this.props.extent)
                    this.dispatch({
                        type: 'fit',
                        extent: this.props.extent,
                    });
                break;
            default:
                // don't preventDefault
                return;
        }
        event.preventDefault();
    };

    toMouseEventHandler = (handler: CustomMouseEventHandler): MouseEventHandler<HTMLDivElement> => {
        return (event) => {
            const { button, altKey, ctrlKey, shiftKey, preventDefault } = event;
            handler({
                screenPos: this.screenPos(event),
                button, altKey, ctrlKey, shiftKey, preventDefault
            });
        }
    }

    getHandlers = (): React.HTMLAttributes<HTMLDivElement> => {
        return {
            onMouseDown: this.toMouseEventHandler(this.onMouseDown),
            onMouseMove: this.toMouseEventHandler(this.onMouseMove),
            onMouseUp: this.toMouseEventHandler(this.onMouseUp),
            onWheel: this.onWheel,
            onKeyDown: this.onKeyDown,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave
        };
    }

    registerEventHandler = (eventHandlerProvider: () => DiagramEventHandler, deps: any[]) => {
        useEffect(() => {
            const handler = eventHandlerProvider();
            this.eventHandlers.push(handler);
            return () => {
                this.eventHandlers.splice(this.eventHandlers.indexOf(handler), 1)
            };
        }, deps);
    };

    internalEventHandler: InternalDiagramEventHandler = {
        onBoxSelection: this.handleBoxSelection,
        onMouseDown: this.onMouseDown,
        onMouseMove: this.onMouseMove,
        onMouseUp: this.onMouseUp,
    };
}

function ViewportInternal(props: React.PropsWithChildren<ViewportProps>) {
    const { children, onClick, onDragStarted, onBoxSelection, onKey, zoomFactor, extent,
        allowClick, allowDrag, initialViewpoint, onViewpointChanged, ...divProps } = props;
    const [state, dispatch] = useReducer(viewportReducer,
        initialViewpoint ? {...initialViewpoint, width: 0, height: 0, autofit: false} : defaultInternalViewportState);
    const { width, height, scale, centerX, centerY } = state;
    const onResize = (newWidth?: number, newHeight?: number) => {
        if (newWidth!==undefined && newHeight!=undefined && width !== newWidth || height !== newHeight)
            dispatch({
                type: 'resize',
                width: newWidth!,
                height: newHeight!
            });
    }
    useEffect(() => {
        if (state.autofit && extent && state.autofitExtent !== extent)
            dispatch({ type: 'fit', extent });
    }, [extent, state.autofit]);
    if(onViewpointChanged)
        useEffect(() => onViewpointChanged(state), [scale, centerX, centerY]);

    const eventHandlers = useMemo(() => new EventHandlers(props), []);
    eventHandlers.props = props;
    eventHandlers.state = state;
    eventHandlers.dispatch = dispatch;
    eventHandlers.interactionType = (allowClick ? 1 : 0) + (allowDrag ? 2 : 0);

    const viewportState = useMemo(
        () => ({
            width, height,
            scale,
            centerX, centerY,
            dispatch,
            registerEventHandler: eventHandlers.registerEventHandler,
            internalEventHandler: eventHandlers.internalEventHandler
        }),
        [width, height, scale, centerX, centerY, dispatch]);

    return <div tabIndex={1} {...eventHandlers.getHandlers()} {...divProps}>
        <ViewportContext.Provider value={viewportState}>
            <ScaleContext.Provider value={scale}>
                {width > 0 && height > 0 ? children : null}
            </ScaleContext.Provider>
        </ViewportContext.Provider>
        <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
    </div>;
}

ViewportInternal.defaultProps = {
    zoomFactor: 1.1,
    allowClick: true,
    allowDrag: true,
};

export const Viewport = React.memo(ViewportInternal);
