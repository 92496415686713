import { createAction, getType } from "typesafe-actions";
import { IStoreActions, StoreState } from ".";
import {
    SearchElementsRequest,
    SearchElementsResult,
    SearchSource
} from "../proto/control_pb";
import { ControlProject } from "../proto/control_pb_service";
import { GrpcActionPayload, grpcRequest } from "./grpc";
import { stdOnError } from "./grpcutils";
import { unsubscribed } from "./project";
import { ThunkDispatch, ThunkResult } from "./store-types";

const searchLimit = 100;

export const updateSearchText = createAction(
    "search/updateSearchResult",
    (resolve) => {
        return (searchText: string) =>
            resolve({ searchText });
    }
);
export const updatePendingProteus = createAction(
    "search/updatePendingProteus",
    (resolve) => {
        return (pendingProteus: boolean) =>
            resolve({ pendingProteus });
    }
);
export const updatePendingInstrumentIndex = createAction(
    "search/updatePendingInstrumentIndex",
    (resolve) => {
        return (pendingInstrumentIndex: boolean) =>
            resolve({ pendingInstrumentIndex });
    }
);
export const clearSearchResults = createAction(
    "search/clearSearchResults",
    (resolve) => {
        return () =>
            resolve();
    }
);
export const updateSearchResultProteus = createAction(
    "search/updateSearchResultProteus",
    (resolve) => {
        return (pendingProteus: boolean, proteusResults: SearchElementsResult.AsObject[], moreProteus: boolean) =>
            resolve({ pendingProteus, proteusResults, moreProteus });
    }
);
export const updateSearchResultInstrumentIndex = createAction(
    "search/updateSearchResultInstrumentIndex",
    (resolve) => {
        return (pendingInstrumentIndex: boolean, instrumentIndexResults: SearchElementsResult.AsObject[], moreInstrumentIndex: boolean) =>
            resolve({ pendingInstrumentIndex, instrumentIndexResults, moreInstrumentIndex });
    }
);

export const searchActions = {
    updateSearchText,
    updatePendingProteus,
    updatePendingInstrumentIndex,
    clearSearchResults,
    updateSearchResultProteus,
    updateSearchResultInstrumentIndex
};

export interface SearchState {
    searchText: string;
    pendingProteus: boolean;
    pendingInstrumentIndex: boolean;
    proteusResults: SearchElementsResult.AsObject[];
    instrumentIndexResults: SearchElementsResult.AsObject[];
    moreProteus: boolean;
    moreInstrumentIndex: boolean;
}

const initialState: SearchState = {
    searchText: "",
    pendingProteus: false,
    pendingInstrumentIndex: false,
    proteusResults: [],
    instrumentIndexResults: [],
    moreProteus: false,
    moreInstrumentIndex: false
};

export function searchReducer(
    state = initialState,
    action: IStoreActions
): SearchState {
    switch (action.type) {
        case getType(updateSearchText):
            return {...state, searchText: action.payload.searchText }
        case getType(updatePendingProteus):
            return {...state, pendingProteus: action.payload.pendingProteus }
        case getType(updatePendingInstrumentIndex):
            return {...state, pendingInstrumentIndex: action.payload.pendingInstrumentIndex }
        case getType(clearSearchResults):
            return {...state, proteusResults: [], instrumentIndexResults: [] }
        case getType(updateSearchResultProteus):
            return {...state, pendingProteus: action.payload.pendingProteus, proteusResults: state.proteusResults.concat(action.payload.proteusResults), moreProteus: action.payload.moreProteus };
        case getType(updateSearchResultInstrumentIndex):
            return {...state, pendingInstrumentIndex: action.payload.pendingInstrumentIndex, instrumentIndexResults: state.instrumentIndexResults.concat(action.payload.instrumentIndexResults), moreInstrumentIndex: action.payload.moreInstrumentIndex };
        case getType(unsubscribed):
            return initialState;
        default:
            return state;
    }
}

export const searchInit = (searchText: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        dispatch(clearSearchResults());

        const proteusRequest = new SearchElementsRequest();
        proteusRequest.setProjectid(projectId);
        proteusRequest.setSearchtext(searchText);
        proteusRequest.setSource(SearchSource.PROTEUS);
        proteusRequest.setStart(0);
        proteusRequest.setEnd(searchLimit+1);

        const instrumentIndexRequest = new SearchElementsRequest();
        instrumentIndexRequest.setProjectid(projectId);
        instrumentIndexRequest.setSearchtext(searchText);
        instrumentIndexRequest.setSource(SearchSource.INSTRUMENTINDEX);
        instrumentIndexRequest.setStart(0);
        instrumentIndexRequest.setEnd(searchLimit+1);

        const proteusResults: SearchElementsResult.AsObject[] = [];
        const instrumentIndexResults: SearchElementsResult.AsObject[] = [];

        var moreP = false;
        var moreII = false;

        const grpcActionProteus: GrpcActionPayload<
            SearchElementsRequest,
            SearchElementsResult
        > = {
            methodDescriptor: ControlProject.SearchElements,
            onError: (code, msg) =>
                stdOnError(dispatch, "Searching elements")(code, msg),
            onMessage: (result: SearchElementsResult) => {
                proteusResults.push(result.toObject());
            },
            onEnd: () => {
                if (proteusResults.length === searchLimit+1) {
                    proteusResults.pop();
                    moreP = true;
                }
                dispatch(updateSearchText(searchText));
                dispatch(updateSearchResultProteus(false, proteusResults, moreP));
            },
            request: proteusRequest,
        };
        const grpcActionInstrumentIndex: GrpcActionPayload<
            SearchElementsRequest,
            SearchElementsResult
        > = {
            methodDescriptor: ControlProject.SearchElements,
            onError: (code, msg) =>
                stdOnError(dispatch, "Searching elements")(code, msg),
            onMessage: (result: SearchElementsResult) => {
                instrumentIndexResults.push(result.toObject());
            },
            onEnd: () => {
                if (instrumentIndexResults.length === searchLimit+1) {
                    instrumentIndexResults.pop();
                    moreII = true;
                }
                dispatch(updateSearchText(searchText));
                dispatch(updateSearchResultInstrumentIndex(false, instrumentIndexResults, moreII));
            },
            request: instrumentIndexRequest,
        };
        dispatch(grpcRequest(grpcActionProteus));
        dispatch(grpcRequest(grpcActionInstrumentIndex));
    };
};

export const searchMore = (searchText: string, source: number, start: number): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project } = getState();
        const { projectId } = project;

        source === 0 ?
            dispatch(updatePendingProteus(true)) :
            dispatch(updatePendingInstrumentIndex(true));

        const request = new SearchElementsRequest();
        request.setProjectid(projectId);
        request.setSearchtext(searchText);
        request.setSource(source === 0 ? SearchSource.PROTEUS : SearchSource.INSTRUMENTINDEX);
        request.setStart(start);
        request.setEnd(start + searchLimit+1);

        const results: SearchElementsResult.AsObject[] = [];
        var more = false;
        var r: SearchElementsResult.AsObject[] = [];

        const grpcAction: GrpcActionPayload<
            SearchElementsRequest,
            SearchElementsResult
        > = {
            methodDescriptor: ControlProject.SearchElements,
            onError: (code, msg) =>
                stdOnError(dispatch, "Searching elements")(code, msg),
            onMessage: (result: SearchElementsResult) => {
                results.push(result.toObject());
            },
            onEnd: () => {
                if (results.length % searchLimit === 1) {
                    r = results.slice(results.length-(searchLimit+1), results.length-1);
                    more = true;
                } else {
                    r = results.slice(results.length-(searchLimit+1));
                }
                source === 0 ?
                    dispatch(updateSearchResultProteus(false, r, more)) :
                    dispatch(updateSearchResultInstrumentIndex(false, r, more));
            },
            request
        };
        dispatch(grpcRequest(grpcAction));
    };
};
