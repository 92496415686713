/**
 * This is workaround way to check if a font is available by
 * utilizing text width measurement and font resolution mechanism
 * copied from https://www.samclarke.com/javascript-is-font-available/
 *
 * Better way would be to use a new API
 * https://developer.mozilla.org/en-US/docs/Web/API/FontFaceSet
 * but it was not yet available in electron 9.0.2.
 */
export const isFontAvailableBuilder = (document: Document) => {
    let width;
    const body = document.body;

    const container = document.createElement('span');
    container.innerHTML = Array(100).join('wi');
    container.style.cssText = [
        'position:absolute',
        'width:auto',
        'font-size:128px',
        'left:-99999px'
    ].join(' !important;');

    const getWidth = function (fontFamily: string) {
        container.style.fontFamily = fontFamily;

        body.appendChild(container);
        width = container.clientWidth;
        body.removeChild(container);

        return width;
    };

    // Pre compute the widths of monospace, serif & sans-serif
    // to improve performance.
    const monoWidth = getWidth('monospace');
    const serifWidth = getWidth('serif');
    const sansWidth = getWidth('sans-serif');

    return (font: string) => {
        return monoWidth !== getWidth(font + ',monospace') ||
            sansWidth !== getWidth(font + ',sans-serif') ||
            serifWidth !== getWidth(font + ',serif');
    };
}

export const isFontAvailable = isFontAvailableBuilder(document);

const FONT_MAP: { [key: string]: string } = {};
const DEFAULT_FONT = "Arial";

export function getAvailableFont(fontName: string | null, notificationCallback: (message: string) => void): string {
    if (!fontName)
        return DEFAULT_FONT;
    const availableFont = FONT_MAP[fontName];
    if (availableFont)
        return availableFont;
    else if (isFontAvailable(fontName)) {
        console.log(fontName + " is available");
        FONT_MAP[fontName] = fontName;
        return fontName;
    }
    else {
        console.log(fontName + " is not available");
        if (notificationCallback)
            notificationCallback("Didn't find font " + fontName + ". Using " + DEFAULT_FONT + " instead.");
        FONT_MAP[fontName] = DEFAULT_FONT;
        return DEFAULT_FONT;
    }
}
