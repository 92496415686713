import { Viewpoint } from "../diagram-viewport";
import produce from "immer";
import { createAction, getType } from "typesafe-actions";
import { IStoreActions, StoreState } from ".";
import { getElementCache } from "../Diagrams/ElementCache";
import { API_URL, USE_PORTAL } from "../utils/constants";
import { unsubscribed } from "./project";
import { fromObjectId } from "./projectTypes";
import { ThunkDispatch, ThunkResult } from "./store-types";

// Types

function viewpointEquals(a?: Viewpoint, b?: Viewpoint): boolean {
    if (a === undefined)
        return b === undefined;
    else
        return b !== undefined &&
            a.centerX == b.centerX && a.centerY == b.centerY && a.scale === b.scale;
}


// Actions

export const receivedDiagram = createAction('diagrams/receivedDiagram', (resolve) => {
    return (contentId: string, document: Document) => resolve({ contentId, document });
});

export const updateLastAccess = createAction('diagrams/updateLastAccess', (resolve) => {
    return (contentId: string, timestamp: number) => resolve({ contentId, timestamp });
});

export const updateViewpoint = createAction('diagrams/updateViewpoint', (resolve) => {
    return (diagramId: string, viewpoint: Viewpoint) => resolve({ diagramId, viewpoint });
});

export const fitToSelection = createAction('diagrams/fitToSelection', (resolve) => {
    return (contentId: string, diagramId: string, selection: string[]) => resolve({ contentId, diagramId, selection });
});

export const diagramsActions = {
    receivedDiagram,
    updateLastAccess,
    updateViewpoint,
    fitToSelection
};

// State

export interface DiagramsState {
    documents: { [id: string]: Document };
    lastAccess: { [id: string]: number };
    viewpoints: { [id: string]: Viewpoint };
    pendingFitToSelection: { [contentId: string]: { diagramId: string, selection: string[] } }
}

const initialState: DiagramsState = {
    documents: {},
    lastAccess: {},
    viewpoints: {},
    pendingFitToSelection: {}
}

export function getDocumentByDiagramId(state: StoreState, diagramId: string) {
    const diagram = state.project.diagrams.get(diagramId);
    if (diagram === undefined || diagram.content === undefined)
        return undefined;
    return state.diagrams.documents[fromObjectId(diagram.content)];
}

export function getDocumentByPossibleDiagramId(state: StoreState, diagramId?: string) {
    if (diagramId === undefined)
        return undefined;
    else
        return getDocumentByDiagramId(state, diagramId);
}

// Reducer
function setViewpoint(state: DiagramsState, diagramId: string, viewpoint: Viewpoint): DiagramsState {
    return {
        ...state,
        viewpoints: {
            ...state.viewpoints,
            [diagramId]: viewpoint
        }
    };
}

export function diagramsReducer(state = initialState, action: IStoreActions): DiagramsState {
    if (action.type === getType(receivedDiagram)) {
        const { contentId, document } = action.payload;
        console.log("Received diagram", contentId);
        const pendingFit = state.pendingFitToSelection[contentId];
        return produce(state, (draft: DiagramsState) => {
            draft.documents[contentId] = document;
            if (pendingFit) {
                const { diagramId, selection } = pendingFit;
                const viewpoint = getElementCache(document).viewpointFromIds(selection);
                if (viewpoint)
                    state.viewpoints[diagramId] = viewpoint;
                delete draft.pendingFitToSelection[contentId];
            }
        });
    }
    else if (action.type === getType(updateLastAccess)) {
        const { contentId, timestamp } = action.payload;
        return {
            ...state,
            lastAccess: {
                ...state.lastAccess,
                [contentId]: timestamp
            }
        };
    }
    else if (action.type === getType(updateViewpoint)) {
        const { diagramId, viewpoint } = action.payload;
        if (viewpointEquals(state.viewpoints[diagramId], viewpoint))
            return state;
        else
            return setViewpoint(state, diagramId, viewpoint);
    }
    else if (action.type === getType(fitToSelection)) {
        const { contentId, diagramId, selection } = action.payload;
        const document = state.documents[contentId];
        if (document === undefined) {
            return produce(state,
                draft => { draft.pendingFitToSelection[contentId] = { diagramId, selection }; });
        }
        else {
            const viewpoint = getElementCache(document).viewpointFromIds(selection);
            if (viewpoint)
                return setViewpoint(state, diagramId, viewpoint);
            else
                return state;
        }
    }
    else if (action.type === getType(unsubscribed)) {
        return initialState;
    }
    else
        return state;
}

// Derived actions

export const ensureDiagramAvailable = (diagramContentId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        dispatch(updateLastAccess(diagramContentId, new Date().getTime()));

        const { project, diagrams } = getState();

        // Do we have a cached document?
        const document = diagrams.documents[diagramContentId];
        if (document)
            return;

        const { projectId } = project;
        if (projectId === '')
            return;
        const { id_token } = USE_PORTAL ? getState().oidc.user! : { id_token: '' };
        console.log("Fetch diagram", diagramContentId);
        fetch(`${API_URL}/projects/${projectId}/diagramContents/${diagramContentId}`, {
            headers: { Authorization: 'Bearer ' + id_token }
        }).then((response) => {
            response.text().then(documentText => {
                const parser = new DOMParser();
                const document = parser.parseFromString(documentText, 'text/xml');
                dispatch(receivedDiagram(diagramContentId, document));
            }).catch((error) => console.log(error));
        }).catch((error) => console.log(error));
    };
};

export const doFitToSelection = (diagramId: string, selection: string[]): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const state = getState();
        const diagram = state.project.diagrams.get(diagramId);
        if (!diagram || !diagram.content)
            return;
        dispatch(fitToSelection(fromObjectId(diagram.content), diagramId, selection));
    };
};
