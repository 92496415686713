import { createAction, getType } from "typesafe-actions";
import { IStoreActions } from ".";

export const toggleNodeTableExpander = createAction(
    "patternEditorExpanders/toggleNodeTableExpander",
    (resolve) => {
        return () =>
            resolve();
    }
);
export const toggleEdgeTableExpander = createAction(
    "patternEditorExpanders/toggleEdgeTableExpander",
    (resolve) => {
        return () =>
            resolve();
    }
);
export const toggleSuggestionsExpander = createAction(
    "patternEditorExpanders/toggleSuggestionsExpander",
    (resolve) => {
        return () =>
            resolve();
    }
);
export const toggleTargetTableExpander = createAction(
    "patternEditorExpanders/toggleTargetTableExpander",
    (resolve) => {
        return () =>
            resolve();
    }
);

export const patternEditorExpandersActions = {
    toggleNodeTableExpander,
    toggleEdgeTableExpander,
    toggleSuggestionsExpander,
    toggleTargetTableExpander,
};

export interface patternEditorExpandersState {
    showNodeTable: boolean;
    showEdgeTable: boolean;
    showSuggestions: boolean;
    showTargetTable: boolean;
}

const initialState: patternEditorExpandersState = {
    showNodeTable: true,
    showEdgeTable: true,
    showSuggestions: true,
    showTargetTable: true,
};

export function patternEditorExpandersStateReducer(
    state = initialState,
    action: IStoreActions
): patternEditorExpandersState {
    switch (action.type) {
        case getType(toggleNodeTableExpander):
            return {...state, showNodeTable: !state.showNodeTable }
        case getType(toggleEdgeTableExpander):
            return {...state, showEdgeTable: !state.showEdgeTable }
        case getType(toggleSuggestionsExpander):
            return {...state, showSuggestions: !state.showSuggestions }
        case getType(toggleTargetTableExpander):
            return {...state, showTargetTable: !state.showTargetTable }
        default:
            return state;
    }
}
